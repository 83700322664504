import styled from 'styled-components';
import { GoArrowSmallRight, GoArrowSmallLeft} from 'react-icons/go'
import { Link } from 'react-router-dom';
import {MdArrowForward, MdKeyboardArrowRight} from 'react-icons/md';

export const PdfContainer = styled.div`
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    background-color: #000;
    min-height: 1050px;
    padding-top: 100px;
`

export const PDFDocumentWrapper = styled.div`

  @media screen and  (max-width: 600px) {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;


export const PdfWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    padding-top: 20px;
    padding-bottom: 20px;

    justify-content: center;
`

export const PageNumber = styled.p`
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 25px;
    color: #DECFAC;
    display: flex;
    justify-content: center;
    font-family: Luxurious Roman;
`


export const ArrowLeft = styled(GoArrowSmallLeft)`
  left: 0.01%;
  font-size: 6rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  color: #DECFAC;

  @media screen and  (max-width: 600px) {
    font-size: 4rem;
  }
`

export const ArrowRight = styled(GoArrowSmallRight)`
  right: 0.01%;
  font-size: 6rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  color: #DECFAC;

  @media screen and  (max-width: 600px) {
    font-size: 4rem;
  }
  
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 30px;
  z-index: 10;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRightBtn = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const DownloadButton = styled(Link)`
    font-family: Luxurious Roman;
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#DECFAC': '#fff')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#000': '#fff')};
    font-size: ${({fontBig})=>(fontBig ? '30px': '26px')};
    outline:none;
    border:none;
    cursor:pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#ffeb81')};
    }
`


export const PageControleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`


export const Options = {
  "particles": {
    "number": {
      "value": 355,
      "density": {
        "enable": true,
        "value_area": 789.1476416322727
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.48927153781200905,
      "random": false,
      "anim": {
        "enable": true,
        "speed": 0.2,
        "opacity_min": 0,
        "sync": false
      }
    },
    "size": {
      "value": 3,
      "random": true,
      "anim": {
        "enable": true,
        "speed": 2,
        "size_min": 0,
        "sync": false
      }
    },
    "line_linked": {
      "enable": false,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 0.2,
      "direction": "none",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "bubble"
      },
      "onclick": {
        "enable": true,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 83.91608391608392,
        "size": 1,
        "duration": 3,
        "opacity": 1,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
}
