import styled from 'styled-components';

export const StoryPageContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  flex-direction: column;
  background-size: cover;
`

export const Background = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    --o-object-fit: cover;
    object-fit: cover;
    box-shadow: 0px -20px 60px 30px #decfac;

`