import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  position: relative;
  background-color: #000;
`;

export const FooterWrap = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  width: auto;
  box-sizing: border-box;
  color: #fff;


  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-family: Luxurious Roman;
  font-size: 25px;
  font-weight: 900;
  padding-bottom: 16px;
  padding-top: 16px;
  color: #DECFAC;
`;

export const FooterLink = styled(Link)`
  font-family: Roboto;
  font-weight: 900;
  color: #fff;
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-size: 16px;

  &:hover {
    color: #DECFAC;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 100%;
  width: 100%;
  align-items: center;
  width: 50%;
  right: 50%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  font-family: Luxurious Roman;
  font-size: 13px;
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
  padding-right: 10px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;

`;

export const SocialIconLink = styled(Link)`
  color: #fff;
  font-size: 24px;

  :hover{
        transform: scale(1.2);
        color: #DECFAC;
    }
`;

export const FooterDescription = styled.div`
font-family: Roboto;
display: flex;
max-width: 500px;
font-size: 16px;
padding-bottom: 1rem;
`

export const FooterDescriptionAddress = styled.div`
display: flex;
max-width: 500px;
font-size: 14px;
padding-bottom: 1rem;

@media screen and (max-width: 440px) {
  font-size: 12px;
}

@media screen and (max-width: 380px) {
  font-size: 10px;
}

@media screen and (max-width: 325px) {
  font-size: 9px;
}

`


export const FooterTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;

    
    justify-content: center;
    color: #fff;
`


export const ColoredLine = styled.hr`
    color: #fff;
    background-color: #fff;
    height: 2px;
    width: 100%;
`;

export const Emojii = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
`;