import React from 'react'
import NFTpreviewCard from './NFTpreviewCard';
import { NFTpreviewData } from './NFTpreviewData';

import {Container, Wrapper, ColoredLine} from './NFTpreviewElements';

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

function NFTpreview() {
    return (
        <>
            <Container id='NFT preview'>

            <ParallaxProvider>
                <Parallax x={[-30, 20]} tagOuter="figure">
                    <Wrapper>
                        {NFTpreviewData.map((data, index) => 
                                <NFTpreviewCard data = {data} key={index} />
                        )}
                    </Wrapper>
                </Parallax>
            </ParallaxProvider>

            <ParallaxProvider>
                <Parallax x={[30, -20]} tagOuter="figure">
                    <Wrapper>
                        {NFTpreviewData.map((data, index) => 
                                <NFTpreviewCard data = {data} key={index} />
                        )}
                    </Wrapper>
                </Parallax>
            </ParallaxProvider>

            </Container>

            <ColoredLine color='black' />


        </>
    )
}

export default NFTpreview
