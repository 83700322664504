import React from 'react';
import { useMotionValue, useTransform } from "framer-motion";
import { Marginer } from "./marginer";

import {CardWrapper,
        CardContainer,
        CircleWrapper,
        Circle,
        TopContainer,
        BottomContainer,
        Text,
        NFTWrapper,
        NFT,
        DetailsContainer,
        MediumText,
        SmallText,
        /*SpacedHorizontalContainer,
        BuyButton,*/
        Logo} from './NFTmarketElements'

import logo from '../../../../ImagesVideo/LennyFaceLogo.webp'; 


function NFTcard( {data} ) {

    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const rotateX = useTransform(y, [-100, 100], [30, -30]);
    const rotateY = useTransform(x, [-100, 100], [-30, 30]);


    return (
        <>
                <CardWrapper>
                    <CardContainer
                        style={{ x, y, rotateX, rotateY, z: 100, backgroundImage:`url(${data.background})`, width: 1000 }}
                        drag
                        dragElastic={0.16}
                        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        whileTap={{ cursor: "grabbing" }}
                    >
                   
                        <TopContainer>
                            <CircleWrapper>
                                <Circle src={data.circle}/>
                            </CircleWrapper>
                            <NFTWrapper>
                                <NFT
                                style={{ x, y, rotateX, rotateY, rotate: "-25deg", z: 100000 }}
                                drag
                                dragElastic={0.12}
                                whileTap={{ cursor: "grabbing" }} 
                                >
                                <img src={data.NFTimg} alt="NFT"/>
                                </NFT>
                                </NFTWrapper>
                                <Text> {data.title} </Text>   
                        </TopContainer>

                        <BottomContainer>
                        
                        <DetailsContainer>
                            <SmallText> {data.description} </SmallText> 

                            {/* <SpacedHorizontalContainer> */}
                                <MediumText> {data.name} </MediumText>
                            {/* </SpacedHorizontalContainer> */}

                                <Marginer direction="vertical" margin="1.2em" />

                            {/* <SpacedHorizontalContainer>
                                <MediumText> {data.price} </MediumText>
                                 <BuyButton to={{ pathname: data.link }} target="_blank">BUY</BuyButton> 
                            </SpacedHorizontalContainer> */}

                            <Logo>
                                <img src={logo} alt="logo"/>
                            </Logo>
                            
                        </DetailsContainer>

                        </BottomContainer>
                    </CardContainer>
                </CardWrapper>
        </>
    )
}

export default NFTcard