import React from 'react'
import Particles from "react-tsparticles"

import { DisclaimerData } from './DisclaimerData';

import {DisclaimerContainer,
        Textbox,
        TextWrapper,
        Title,
        Paragraph,
        Options} 
        from './DisclaimerElements'

function CoreDisclaimer() {
    
    return (
        <>

        <DisclaimerContainer>

            <Particles id="particle-disclaimer" options={Options} style={{ position: 'fixed'}}/>

            <Textbox id='Risk Disclaimer'>

                <TextWrapper>

                    <Title> {DisclaimerData[0]['title']} </Title>
                    <Paragraph style={{ textAlign: 'justify',fontStyle: 'italic' }}> {DisclaimerData[0]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[1]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[2]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[3]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[4]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {DisclaimerData[5]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[5]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {DisclaimerData[6]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[6]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontStyle: 'italic', fontSize: '20px' }}> {DisclaimerData[7]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[7]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontStyle: 'italic', fontSize: '20px' }}> {DisclaimerData[8]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[8]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontStyle: 'italic', fontSize: '20px' }}> {DisclaimerData[9]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[9]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[10]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[11]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {DisclaimerData[12]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[12]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[13]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {DisclaimerData[14]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[14]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {DisclaimerData[15]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[15]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {DisclaimerData[16]['paragraph']} </Paragraph>

                </TextWrapper>
            </Textbox>
        
        </DisclaimerContainer>
            
        </>
    )
}

export default CoreDisclaimer