import styled from 'styled-components';
import bg from '../../../../ImagesVideo/BackStory/storyEnv.webp'
import {MdArrowForward, MdKeyboardArrowRight} from 'react-icons/md';


export const Container = styled.div`
  align-items: center;
  justify-content: center;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

`
export const StoryTitle = styled.div`
  font-family: 'Island Moments';
  font-size: 100px;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;

`

export const TextWrapper = styled.div`  
  justify-content: center;
  align-items: center;
  background-color: #000;
  opacity: 80%;
  border-radius: 5px;
  border: 3px solid #DECFAC;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 850px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Luxurious Roman';
  color: #DECFAC;
  font-size: 36px;
  line-height: 16px;
  font-weight: 900;
  letter-spacing: 1.4px;
  padding-bottom: 15px;

  @media screen and (max-width: 850px) {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const Paragraph = styled.p`

  justify-content: center;
  font-weight: 900;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 22pt;
  color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
  text-align: justify;

  @media screen and (max-width: 850px) {
    font-size: 14px;
  }

`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  

`;


export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRightBtn = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const InfoWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 500px;
  padding-right: 500px;
  justify-content: center;

  @media screen and (max-width: 1500px) {
    padding-left: 180px;
    padding-right: 180px;
  }

  @media screen and (max-width: 850px) {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) => imgStart ? "'col2 col1'" : "'col1 col2'"};

  @media screen and (max-width: 850px) {
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;