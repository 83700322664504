import styled from 'styled-components';


export const Container = styled.div`
    color: #fff;
    height: auto;
    padding-bottom: 40px;
    background-color: #000;
    overflow: hidden;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(18, minmax(14rem, 16rem));
    grid-gap: 30px;
    position: float;
    background-color: #000;    
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const CardContainer = styled.div`
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 3px solid #DECFAC;

    background-color: #fff;
    transition:0.5s;
    
    animation: ease-in-out;
    
    align-items: center;
    justify-content: center;

    
    :hover{
        transform: scale(1.02);
        box-shadow: 0px 0px 15px 0px;
    }
`;

export const ImageWrapper = styled.div`
    align-items: center;
    justify-content: center;
    background-color: transparent;
    min-height: 100%;
    min-width: 100%;

`

export const Image = styled.img`
    overflow: hidden;
    height: 100%;
    width: 100%;
`;


export const ColoredLine = styled.hr`
    height: 2px;
    width: 100%;
`;