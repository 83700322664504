import React from 'react'
import {
    TeamCardContainer, 
    Image, CardTitle, 
    CardTextWrapper, 
    ImageWrapper
} from './TeamElements'

// CardDescription, 
// SocialIcons, 
// SocialIconLink

// import {FaLinkedin, FaTelegram, FaTwitter} from 'react-icons/fa'

function TeamCard( {data} ) {
    return (
        <>
            <TeamCardContainer> 
                
                <ImageWrapper> 
                    <Image src={data.photo}/>
                </ImageWrapper>

                <CardTextWrapper>

                    <CardTitle> {data.name} </CardTitle>
                    {/* <CardDescription> {data.description} </CardDescription> */}

                </CardTextWrapper>

                {/* <SocialIcons>
                    <SocialIconLink to={{ pathname: data.linkedin}} target="_blank">
                        <FaLinkedin />
                    </SocialIconLink>

                    <SocialIconLink to={{ pathname: data.twitter }} target="_blank">
                        <FaTwitter />
                    </SocialIconLink>

                    <SocialIconLink to={{ pathname: data.telegram }} target="_blank">
                        <FaTelegram />
                    </SocialIconLink>
                </SocialIcons> */}

            </TeamCardContainer>
        </>
    )
}

export default TeamCard
