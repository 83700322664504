export const RoadmapData = [
    {
        id: 1,
        title: "Stage 1: Lenny on the Launch Pad",
        description: `The Community IDO Presale Period is available now on Binance Smart Chain (BSC) and will last 20 days. 
        The Dev Team's goal is to ensure that the event takes place in the fairest and safest environment possible. In parallel, 
        the team will intensively work on the development of the NFT Marketplace.`,
        date:'2021 Q3',
    },
    {
        id: 2,
        title: "Stage 2: Launch",
        description: `By the end of the Community IDO Presale Period, we will start the deployment of the LENNY/BUSD Liquidity 
        Pool on PancakeSwap (BSC). This marks LENNY's entry into the secondary market. The Dev Team will finalize the development of 
        the NFT Marketplace while continuing to improve the animated story around Lenny’s universe.`,
        date:'2021 Q3',

    },
    {
        id: 3,
        title: "Stage 3: NFT Marketplace",
        description: `The Dev Team will launch the NFT Marketplace centered around Lenny's story. The team will be in charge of the writing. 
        An art ecosystem will be built around the character of Lenny. The Dev Team will devote most of its efforts in creating a well defined 
        community-driven environment while continuing to refine the project as a whole.`,
        date:'2021 Q3',

    },
    {
        id: 4,
        title: "Stage 4: The Multi Verse",
        description: `The Dev Team will allow the LennyVerse community members to submit their own version of Lenny's story, illustrating 
        the birth of parallel universes. Art ecosystems will be built around the chosen stories which would carry messages and support 
        humanitarian causes. This will result in a more participative project with a purpose.`,
        date:'2021 Q4',

    },
    {
        id: 5,
        title: "Stage 5: The Lenny Fest",
        description: `The Dev Team will further expand the project by organizing community events to further 
        develop the art ecosystem concept. "Lenny Fest" events will allow the community to share their own digital art 
        and content creations, which will not necessarily be related to the character of Lenny and its story. `,
        date:'2021 Q4',
    },
    {
        id: 6,
        title: "Stage 6: Multi-Blockchain Ecosystem",
        description: `The ultimate goal is to become a multi-blockchain utility token with interoperability features.
        The project will even extend beyond the realm of art in the sense that there will be a rich Lenny ecosystem composed 
        of multiple smaller ecosystems offering applications to use cases and operating in different blockchains at once.`,
        date:'2021 Q4',

    }
]