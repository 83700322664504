import React from 'react'
import TeamCard from './TeamCard';
import { TeamData } from './TeamData';

import {TeamContainer, TeamWrapper, TeamTitle, ColoredLine} from './TeamElements';

function Team() {
    return (
        <>
            <TeamContainer id='team'>

                <TeamTitle> Team </TeamTitle>

                <TeamWrapper>
                    {TeamData.map((data, index) => 
                            <TeamCard data = {data} key={index} />
                    )}
                    </TeamWrapper>

            </TeamContainer>

            <ColoredLine color='black' />


        </>
    )
}

export default Team
