import React from 'react'

import {FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems,
    FooterLinkTitle, FooterLink, FooterDescription, SocialLogo, SocialMedia, WebsiteRights,
    SocialIcons, SocialMediaWrap, FooterTextWrap,
    SocialIconLink, ColoredLine, FooterDescriptionAddress, Emojii} from './FooterElements'

import Logo from '../Logo/Logo'
import { FaDiscord, FaGithub, FaYoutube, FaTwitter} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';


import {FooterText1, FooterText2, FooterText3, DonationAddress} from './FooterData'

function Footer() {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <>
        <FooterContainer>

            <FooterWrap>

            <Emojii>
            🛰 🛰 🛰 🛰 🛰 🛰 🛰 🛰 🛰 🛰 🛰 🛰 🛰
            </Emojii>

                <FooterLinksContainer>

                    <FooterLinksWrapper>

                        <FooterTextWrap>

                            <FooterLinkTitle> Development Team Message </FooterLinkTitle>
                            
                            <FooterDescription>
                                {FooterText1}
                            </FooterDescription>

                            <FooterDescription>
                                {FooterText2}
                            </FooterDescription>

                            <FooterDescriptionAddress style={{fontWeight:'bold'}}>
                                {DonationAddress}
                            </FooterDescriptionAddress>

                            <FooterDescription>
                                {FooterText3}
                            </FooterDescription>

                        </FooterTextWrap>


                        <FooterLinkItems>
                            
                            <FooterLinkTitle> SocialMedia </FooterLinkTitle>

                            <FooterLink to={{ pathname: "https://discord.gg/ky7KkDrtgx" }} target="_blank"> ✨Discord </FooterLink>
                            <FooterLink to={{ pathname: "https://twitter.com/TheLennyVerse" }} target="_blank"> ✨Twitter </FooterLink>
                            <FooterLink to={{ pathname: "https://www.youtube.com/channel/UCtBdEuUedfo5MFfL87VnlMQ" }} target="_blank"> ✨Youtube </FooterLink>
                            <FooterLink to={{ pathname: "https://github.com/LennyVerse" }} target="_blank"> ✨Github </FooterLink>

                        </FooterLinkItems>

                        <FooterLinkItems>
                            
                            <FooterLinkTitle> Ressources </FooterLinkTitle>

                            <FooterLink to='/whitepaper'> ✨White Paper </FooterLink>
                            <FooterLink to={{ pathname: "/termsconditions" }}> ✨Terms&Conditions </FooterLink>
                            <FooterLink to='/policy'> ✨Privacy Policy </FooterLink>
                            <FooterLink to='/disclaimer'> ✨Risk Disclaimer </FooterLink>

                        </FooterLinkItems>

                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <ColoredLine color='black'/>

                <SocialMedia>
                    <SocialMediaWrap>
                        
                        <SocialLogo to='/' onClick={toggleHome}>
                            <Logo />
                        </SocialLogo>

                        <WebsiteRights>
                        LennyVerse © {new Date().getFullYear()} All rights reserved.
                        </WebsiteRights>

                        <SocialIcons>
                            <SocialIconLink to={{ pathname: "https://twitter.com/TheLennyVerse" }} target="_blank">
                                <FaTwitter />
                            </SocialIconLink>

                            <SocialIconLink to={{ pathname: "https://discord.gg/ky7KkDrtgx" }} target="_blank">
                                <FaDiscord />
                            </SocialIconLink>
                            
                            <SocialIconLink to={{ pathname: "https://www.youtube.com/channel/UCtBdEuUedfo5MFfL87VnlMQ" }} target="_blank">
                                <FaYoutube/>
                            </SocialIconLink>

                            <SocialIconLink to={{ pathname: "https://github.com/LennyVerse" }} target="_blank">
                                <FaGithub/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>

                </SocialMedia>

            </FooterWrap>
        </FooterContainer>
            
        </>
    )
}

export default Footer
