import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const HeaderContainer = styled.nav`
    ${({ scrollHead }) => (scrollHead ? 'animation-name: dissapear;background: #101522;': 'animation-name: appear;background: transparent;')}
    animation-duration: 0.5s;
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 900;
`;


export const  HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 120;
    width: 100%;
    padding: 0 24px;
    max-width: 1200px;

`;

export const LogoWrapper = styled(LinkR)`
    font-family: Island Moments;
    color: #F7E7CE;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 50px;
    letter-spacing: 1.4px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #F7E7CE;
    }
`;

export const HeaderMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 960px) {
        display: none;
    }
`;


export const HeaderItem = styled.li`
    
    height: 80px;
    display: flex;
    align-items: center;
`;

export const HeaderBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 960px) {
        display: none;
    }
`;


export const HeaderLinks = styled(LinkR)`
    color: #fff;
    font-family: Luxurious Roman;
    font-size: 25px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active{
        border-bottom: 3px solid #DECFAC;
    }

    :hover{
        transition: all 0.2s ease-in-out;
        color:#F7E7CE
    }
`;


export const HeaderBtnLink = styled(LinkR)`
    font-family: Luxurious Roman;
    border-radius: 50px;
    background: #DECFAC;
    white-space: nowrap;
    padding: 10px 12px;
    color: 	#010606;
    font-size: 25px;
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color:#000
    }
`;

export const HeaderSocialsLink = styled(LinkR)`
    border-radius: 10px;
    background: #fff;
    justify-content: center;
    white-space: nowrap;
    padding: 4px 8px;
    color: 	#010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;

    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #000;
        color:#fff
    }
`;


