import image1 from '../../../../ImagesVideo/Home/Lenny.webp';
import image2 from '../../../../ImagesVideo/Home/Lenny2.webp';
import image3 from '../../../../ImagesVideo/Home/Lenny3.webp';


export const HomeInfoData = [
    {id:"0",
    title: 'Art Ecosystem and Character',
    description: `The LennyVerse project is meant to  be an art ecosystem that will promote content creation. 
    LENNY can be defined as a utility token in the sense that its use is purely reserved to interact with the 
    LennyVerse ecosystem. `,
    image: image1},
    {id:"1",
    title: 'The LennyVerse NFT Marketplace',
    description: `The LennyVerse ecosystem is essentially centered around its NFT Marketplace which is itself 
    revolving around Lenny. As the story progresses, an NFT ecosystem supporting humanitarian causes and 
    involving the community will be created.`,
    image: image2},
    {id:"2",
    title: 'Community-Driven Project',
    description: `The LennyVerse project aims to benefit the entire community. Indeed, it involves a 
    rigorous smart contract testing process (HardHat) and an innovative way to launch a token (CIDO), as well as a 
    robust risk management against Rug Pulls, Pump & Dump and Whales.`,
    image: image3},
];


