import logo from '../../../../ImagesVideo/LennyFaceLogo.webp'; 
import moon from '../../../../ImagesVideo/Roadmap/moon.webp' 
import rocket from '../../../../ImagesVideo/NFTmarket/rocket.webp'; 
import firstlogo from '../../../../ImagesVideo/NFTmarket/firstlogo.webp'; 

import stars from '../../../../ImagesVideo/NFTmarket/stars.webp' 
import stars2 from '../../../../ImagesVideo/NFTmarket/stars2.webp' 
import stars3 from '../../../../ImagesVideo/NFTmarket/stars3.webp' 

export const NFTmarketData = [
    {id : 1,
    title: '',
    name: 'I AM LENNY',
    description: 'The Journey has Begun and the Story will Last Forever.',
    circle: moon,
    NFTimg:logo,
    background: stars,
    price:'50,000 ADA',
    link: 'https://cnft.io/token.php?id=61475f0d14bb454630c3714c'},

    {id : 2,
        title: '',
        name: "LENNY'S ROCKET",
        description: "Lenny's Cutting Edge Partner Through the Space Race.",
        circle: moon,
        NFTimg:rocket,
        background: stars2,
        price:'25,000 ADA',
        link: 'https://cnft.io/token.php?id=61475f157cd13725f7dc563a'},

    {id : 3,
        title: '',
        name: 'THE FIRST LENNY',
        description: 'The Original Lenny Face Coin Logo at the Very Start.',
        circle: moon,
        NFTimg:firstlogo,
        background: stars3,
        price:'10,000 ADA',
        link: 'https://cnft.io/token.php?id=61475f1b893fbf932d565b5e'}
]


export const NFTdescriptionData = [
    {
    id: 0,
    title: "NFT Marketplace"
}, 
{
    id: 1,
    paragraph: `The LennyVerse development team is working on establishing its art ecosystem based on the 
    creation of its NFT Marketplace where a variety of NFTs will be offered through the LennyVerse website. 
    The ecosystem will only be accessible for the community members, identifiable as LENNY holders. There will 
    be three layers of operation regarding the NFT Marketplace.`
}, 
{
    id: 2,
    title: `The Dev Team`,
    paragraph: `The development team will be able to submit 10 NFTs where the community will vote for their 5 favorites. 
    These unique NFTs will essentially focus on the story. The team will also be able to launch collections, 
    limited to 1 per chapter. 
    `
}, 
{
    id: 3,
    title: `The Artists`,
    paragraph: `Special NFTs will be produced by artists who are willing to submit their work for potential partnership. 
    We plan to select up to 10 artists per chapter of the story and each artist will create his/her own digital artwork 
    to be shared on LennyVerse Marketplace. A maximum of 5 depictions of each chapter will be offered through the Marketplace, 
    chosen from the artists mentioned above through a voting system involving the community. These NFTs can only be purchased 
    with LENNY tokens and an auction system will be set up with a reserved price. The artist will receive 80% of the LENNY 
    while the development team will receive the other 20%.
    `
}, 
{
    id: 4,
    title: `The Community`,
    paragraph: `Every member of the LennyVerse community, identifiable as LENNY holders, will be able to share their artwork 
    in the ecosystem (1 NFT per address) in exchange of a small fee of 500 LENNY and a lock of US$50 in LENNY for 1 week, for 
    security reasons. A maximum of 100 NFTs will be accepted to enter a contest system where the rest of the community will be 
    able to vote for their 25 favorites to be offered in the Marketplace. Every community member will have  votes proportional 
    to the amount of LENNY held. The NFTs offered in the Marketplace can only be purchased in LENNY tokens and an auction 
    system will be set up with a reserved price. The starting price of each NFT will be determined according to the number of 
    votes obtained. Just like the case of the artists previously mentioned, there will be a split of 80%/20% between the 
    creator of the artwork and the development team. 
    `
}
]