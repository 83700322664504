import { React } from 'react';
import logo from '../../../../ImagesVideo/LennyFaceLogo.webp';
import {LogoContainer, LogoImage} from './LogoElements'

function Logo() {
    return (
        <LogoContainer>
            <LogoImage src={logo} alt='logo' />
        </LogoContainer>
    )
};

export default Logo