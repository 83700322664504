import React from 'react'

import {ChapOneContainer,
        Background,
        Header,
        Paragraph,
        SubHead,
        Headline1,
        Headline2,
        Headline3,
        Headline4,
        Content,
        Column,
        GIF} 
        from './ChapOneElements'

import gif from '../../../../ImagesVideo/StoryPage/Chapter1/gif.gif' 

    function ChapOne() {

        return (
            <>
            <Background>    
                <ChapOneContainer>
                    <Header>
                        THE LENNY POST
                    </Header>

                    <SubHead>
                        Chapter 1: My Name is Lenny
                    </SubHead>

                    <Content>
                        <Column>
                            <Headline1>
                                PERSON OF THE YEAR
                            </Headline1>
                            <br/>
                            <Headline2>
                                "I HAD A DREAM THAT ONE DAY I WOULD FLY TO THE MOON!"
                            </Headline2>
                            <br/>

                            <Paragraph>
                                “It felt so real I remember every single 
                                detail…” said Lenny. “ It was another casual day for me. I was on my way back home from work. 
                                Back then, I was working as a Senior Director in a reputable innovative Tech firm” said Lenny. In fact, 
                                before being voted as the most influential person of the year, Lenny worked as a Cleaning 
                                Manager for 20 years at FindMyPuppy.com. 
                            </Paragraph>
                            
                            <GIF src={gif}/>

                            <Paragraph>
                                “As I was texting and driving, I came across a SpaceY commercial showcasing my biggest 
                                inspiration: Elon Mush and his tiny cutie "Shit-a Inu" puppy landing on the moon" 
                            </Paragraph>

                        </Column>
 
                        <Column>
                            <Headline3>
                                A call from the universe - Reality or High AF?
                            </Headline3>

                            <Paragraph>
                                This scene appeared before my eyes as a holy manifestation of my future self trying to 
                                send me a message from a parallel universe, provoking me to do something 
                                electrifying that I might regret!” said Lenny, appearing overwhelmingly thrilled.
                            </Paragraph>

                            <Paragraph>
                                Apparently, not only was Lenny texting and driving, but he reveals that he was on some 
                                “strong shroomies”! (All events described above are part of Lenny’s psychedelic and highly 
                                suspicious dream. We, in no way condone this type of activity and behavior).
                            </Paragraph>

                            <Headline3>
                                ELON? Who da f*** is that guy?
                            </Headline3>

                            <Paragraph>
                                The young dreamer looks up to Elon Mush and worships him as the “King of the space pirates who 
                                discovered the truth about the Red Planet's void century”. But instead, Lenny 
                                pictured himself landing on the moon and saw this dream as a sign to turn his life around 
                                and catch up on "lost time" (A.K.A spending the last 10 years trying to
                                reach Silver IV on League of Legends). 
                            </Paragraph>
                        </Column>

                        <Column>

                            <Headline1>
                                Awakening
                            </Headline1>

                            <Paragraph>
                                As he wakes up, Lenny specifically remembers screaming: “Let's go above these 
                                clouds! Let's do something deadly exciting". Lenny skips gaming and calls his five closest 
                                friends asking them to form a team to go nowhere 
                                else but... the MOON. How, you may ask? “By buying a SpaceY Fat-con-Heavy rocket and literally fly up 
                                there” brilliantly explained Lenny. They have connections... yes, Moms around the world, apparently online gaming helps. 
                                In a matter of months, they were able to get a rocket on the launch pad, set forth to explore 
                                outer space.
                            </Paragraph>

                            <Headline4>
                                The golden age of space conquest
                            </Headline4>

                            <Paragraph>
                                The decision was made, and the plan was laid out. It was now up to Lenny to make this dream 
                                his new reality. As we speak, Lenny is walking towards the rocket, wearing his XXL space suit in 
                                order to fit his “huge balls of steel”, he repeatedly said while performing unrecognizable gang 
                                signs with both hands. He is anxious because he is afraid of heights, but one thing is sure: 
                                Lenny is not afraid to take risks.
                            </Paragraph>
                        </Column>
                    </Content>
                </ChapOneContainer>
            </Background>
</>
    )
}

export default ChapOne