import {React, useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import  Logo  from '../Logo/Logo.js';
import { animateScroll as scroll } from 'react-scroll';
import { Dropdown } from 'react-bootstrap';
import { flash } from 'react-animations';
import styled, { keyframes } from 'styled-components';


import {
    HeaderContainer, 
    HeaderWrapper, 
    LogoWrapper, 
    MobileIcon, 
    HeaderMenu, 
    HeaderItem, 
    HeaderLinks, 
    HeaderBtn, 
    HeaderBtnLink,
    HeaderSocialsLink
} from './HeaderElements';

function Header( { toggle } ) {


    const [scrollHead, setScrollHead] = useState(false);

    const changeHead = () => {
      if (window.scrollY >= 80) {
        setScrollHead(true);
      } else {
        setScrollHead(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', changeHead);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const Anim = styled.div`animation: 8s ${keyframes`${flash}`} infinite`;

    return (
        <>
            <HeaderContainer scrollHead={scrollHead}>
                <HeaderWrapper>

                    <LogoWrapper to ='/' onClick={toggleHome}> 
                        <Logo/> LennyVerse
                    </LogoWrapper>

                    <MobileIcon onClick={toggle}> 
                        <FaBars />
                    </MobileIcon> 

                    <HeaderMenu>
                        


                            <HeaderItem>
                                
                                <HeaderLinks 
                                to="/story"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}> Story </HeaderLinks>
                            </HeaderItem>

                        <HeaderItem>
                            <HeaderLinks 
                            to="/marketplace"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}> NFT </HeaderLinks>
                        </HeaderItem>


                        <HeaderItem>
                            <HeaderLinks 
                            to="/whitepaper"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}> Whitepaper </HeaderLinks>
                        </HeaderItem>

                        <HeaderItem>
                                <Dropdown>
                                <Dropdown.Toggle style={{background:'transparent', border:'transparent'}}
                                variant="secondary btn-sm" 
                                id="dropdown-basic">
                                    <HeaderLinks 
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>
                                    BuyNow <Anim style={{fontSize:'30px'}}>🚀</Anim>
                                    </HeaderLinks>
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{backgroundColor:'#73a47'}}>
                                    <HeaderSocialsLink to={{ pathname: "https://lennyverse.medium.com/lennyverse-5f674da02e60"}} target="_blank" style={{position:'relative', top:'15px', left:'-30px', display:'flex'}}> How To Participate?  </HeaderSocialsLink>
                                    <HeaderSocialsLink to={{ pathname: "https://app.lennyverse.io/communityIDO"}} target="_blank" style={{position:'relative', top:'20px', left:'-30px', display:'flex'}}> Community IDO </HeaderSocialsLink>
                                </Dropdown.Menu>
                                </Dropdown>
                        </HeaderItem>

                    </HeaderMenu>
                    

                    <HeaderBtn className="dropdown">
                        <HeaderBtnLink to={{ pathname: "https://app.lennyverse.io/" }} target="_blank">
                            Launch Apps
                        </HeaderBtnLink>
                    </HeaderBtn> 

                    

                </HeaderWrapper>
            </HeaderContainer>
        </>
    )
}

export default Header
