

export const FooterText1 = `✏️ We are really passionate about learning more and more and pushing the 
development of our community project further. If you like what we are doing, we would be very happy 
if you would leave us a message on our discord. We look forward to hearing from you. 💎`

export const FooterText2 = `⚙️ If you would like to donate to the Dev Team, 
please send donations at the following address:`

export const FooterText3 = `💌 Thank you for your support ! 💌`

export const DonationAddress = `0xcEB2355D1d8DC0DE813A45Dd8dcA8A852EB16A75`