import React, {useState, useEffect}  from 'react'

import Sidebar from '../Home/Components/Sidebar/Sidebar'
import Header from '../Home/Components/Header/Header'
import Footer from '../Home/Components/Footer/Footer'
import PdfViewer from './Components/PdfViewer/PdfViewer'

function WhitePaper() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Header toggle={toggle}/>
            <PdfViewer />
            <Footer/>
            
        </>
    )
}

export default WhitePaper
