import React from "react";
import PdfController from "./PdfController";
import file from "../../../../WPpdf/LennyVerse.pdf";
import {PdfContainer, Options} from './PdfViewerElements';

import Particles from "react-tsparticles"

export default function PdfViewerCode() {
  
    return (
        
        <PdfContainer>

                <Particles id="particle-1" options={Options} style={{ position: 'absolute' }}/>

                <PdfController pdf={file} />

        </PdfContainer>
    );

}

