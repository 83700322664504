import React from 'react';
import {RoadmapData} from './RoadmapData'
import {RoadmapContainer, 
        RoadmapWrapper, 
        RoadmapTitle, 
        EarthContainer, 
        MoonContainer,
        RoadmapImage,
        Options} from './RoadmapElements'
        

import  RoadmapItem from './RoadmapItem'
import Particles from "react-tsparticles"

import roadmap from '../../../../ImagesVideo/Roadmap/roadmap.webp' 
import earth from '../../../../ImagesVideo/Roadmap/earth.webp' 
import moon from '../../../../ImagesVideo/Roadmap/moon.webp' 
 

function Roadmap() {

    
    return (
        <>
            <RoadmapContainer id='roadmap'>

                <RoadmapTitle>
                    Lenny's Roadmap
                </RoadmapTitle>

                <RoadmapImage src={roadmap} />

                <EarthContainer src={earth}/>

                <MoonContainer src={moon}/>
                
                <RoadmapWrapper>
                    <Particles id="particle-0"
                                            options={Options}
                                            style={{
                                            position: 'absolute'
                                            }}/>
                
                    {RoadmapData.map((data, index) => 
                        <RoadmapItem data = {data} key={index} />
                    )}

                </RoadmapWrapper>

            </RoadmapContainer>
        </>
    )
}

export default Roadmap
