import React from 'react'

import {StoryPageContainer,
        Background} 
        from './BannerOneElements'


import chap1layer1 from '../../../../ImagesVideo/StoryPage/Chapter1/layer1.webp' 
import chap1layer2 from '../../../../ImagesVideo/StoryPage/Chapter1/layer2.webp' 
import chap1layer3 from '../../../../ImagesVideo/StoryPage/Chapter1/layer3.webp' 
import chap1layer4 from '../../../../ImagesVideo/StoryPage/Chapter1/layer4.webp' 
import chap1layer5 from '../../../../ImagesVideo/StoryPage/Chapter1/layer5.webp' 
import chap1layer6 from '../../../../ImagesVideo/StoryPage/Chapter1/layer6.webp' 
import chap1layer7 from '../../../../ImagesVideo/StoryPage/Chapter1/layer7.webp' 
import chap1layer8 from '../../../../ImagesVideo/StoryPage/Chapter1/layer8.webp' 
import chap1layer9 from '../../../../ImagesVideo/StoryPage/Chapter1/layer9.webp' 
import chap1layer10 from '../../../../ImagesVideo/StoryPage/Chapter1/layer10.webp' 

import {
    ParallaxBanner,
    ParallaxProvider
  } from "react-scroll-parallax";


function BannerOne() {

    return (
        <>
                <StoryPageContainer>
                
                    <ParallaxProvider>
                        <Background>
                            <ParallaxBanner
                                layers={[
                                    {
                                    image:chap1layer1,
                                    amount: 0,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer2,
                                    amount: 0.2,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer3,
                                    amount: 0.3,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer4,
                                    amount: 0.4,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer5,
                                    amount: 0.5,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer6,
                                    amount: 0.6,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer7,
                                    amount: 0.7,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer8,
                                    amount: 0.8,
                                    expanded: false
                                    },
                                    {
                                    image:chap1layer9,
                                    amount: 0.9,
                                    expanded: false
                                    },
                                    {
                                        image:chap1layer10,
                                        amount: 1,
                                        expanded: false
                                        }
                                    ]}
                                    style={{
                                    height: "100vh",
                                    overflow: "auto !important"
                                    }}
                            >
                            </ParallaxBanner>

                        </Background>
                    </ParallaxProvider>

                </StoryPageContainer>
            
        </>
    )
}

export default BannerOne
