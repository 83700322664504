export const ChapTwoData = [
    {
        id: 0,
        lightBg: true,
        leghtText: false,
        lightTextDesc: false,
        title: "Chapter 2: Where is the Moon?",
        paragraph: `To be continued...`
    }
    
    
    ]