import React from 'react'

import {StoryPageContainer,
        Background
    } 
    from './ChapOne/BannerOneElements'


import layer1 from '../../../ImagesVideo/StoryPage/layer1.webp' 
import layer2 from '../../../ImagesVideo/StoryPage/layer2.webp' 
import layer3 from '../../../ImagesVideo/StoryPage/layer3.webp' 
import layer4 from '../../../ImagesVideo/StoryPage/layer4.webp' 
import layer5 from '../../../ImagesVideo/StoryPage/layer5.webp' 
import layer6 from '../../../ImagesVideo/StoryPage/layer6.webp' 

import {
    ParallaxBanner,
    ParallaxProvider
  } from "react-scroll-parallax";



function Hero() {

    return (
        <>
                <StoryPageContainer>

                    <ParallaxProvider>
                        <Background>
                            <ParallaxBanner
                                layers={[
                                    {
                                    image:layer1,
                                    amount: 0,
                                    expanded: false
                                    },
                                    {
                                    image:layer2,
                                    amount: 0.3,
                                    expanded: false
                                    },
                                    {
                                    image:layer3,
                                    amount: 0.5,
                                    expanded: false
                                    },
                                    {
                                    image:layer4,
                                    amount: 0.6,
                                    expanded: false
                                    },
                                    {
                                    image:layer5,
                                    amount: 0.8,
                                    expanded: false
                                    }
                                    ]}
                                    style={{
                                    height: "100vh",
                                    overflow: "auto !important"
                                    }}
                            >
                            </ParallaxBanner>
                        
                            <ParallaxBanner
                                layers={[
                                    {
                                    image:layer6,
                                    amount: 0.9,
                                    expanded: false
                                    }
                                ]}
                                style={{
                                height: "100vh",
                                overflow: "auto !important"
                                }}
                            >
                             </ParallaxBanner>
                        </Background>
                    </ParallaxProvider>
                    

                    </StoryPageContainer>
            
        </>
    )
}

export default Hero