import React from 'react'
import Particles from "react-tsparticles"

import { TermsConditionsData } from './TermsConditionsData';

import {DisclaimerContainer,
        Textbox,
        TextWrapper,
        Title,
        Paragraph,
        Options} 
        from './TermsConditionsElements'

const bullet1 = [
    { id: 1, name: 'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.' },
    { id: 2, name: 'Country refers to: Canada' },
    { id: 3, name: 'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to LennyVerse, Canada.' },
    { id: 4, name: 'Content refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.' },
    { id: 5, name: 'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.' },
    { id: 6, name: 'Feedback means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.' },
    { id: 7, name: 'Goods refer to the items offered for sale on the Service.' },
    { id: 8, name: 'Orders mean a request by You to purchase Goods from Us.' },
    { id: 9, name: 'Promotions refer to contests, sweepstakes or other promotions offered through the Service.' },
    { id: 10, name: 'Service refers to the Website.' },
    { id: 11, name: 'Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.' },
    { id: 12, name: 'Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.' },
    { id: 13, name: 'Website refers to LennyVerse, accessible from https://www.lennyverse.io/' },
    { id: 14, name: 'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.' }
    ];

    const bullet2 = [
        { id: 1, name: 'Goods availability' },
        { id: 2, name: 'Errors in the description or prices for Goods' },
        { id: 3, name: 'Errors in Your Order' }
        ];

    const bullet3 = [
        { id: 1, name: 'The supply of Goods made to Your specifications or clearly personalized.' },
        { id: 2, name: 'The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.' },
        { id: 3, name: 'The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.' },
        { id: 4, name: 'The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.' },
        { id: 5, name: 'The supply of digital content which is not supplied on a tangible medium if the performance has begun with Your prior express consent and You have acknowledged Your loss of cancellation right.' }
        ];

    const bullet4 = [
        { id: 1, name: 'Unlawful or promoting unlawful activity.' },
        { id: 2, name: 'Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.' },
        { id: 3, name: 'Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.' },
        { id: 4, name: 'Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.' },
        { id: 5, name: 'Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.' },
        { id: 6, name: 'Impersonating any person or entity including the Company and its employees or representatives.' },
        { id: 7, name: 'Violating the privacy of any third person.' },
        { id: 8, name: 'False information and features.' },
        ];

    const bullet5 = [
        { id: 1, name: "An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest." },
        { id: 2, name: 'A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.' },
        { id: 3, name: 'Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.' },
        { id: 4, name: 'Your address, telephone number, and email address.' },
        { id: 5, name: 'A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.' },
        { id: 6, name: "A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf." },
        ];


function TermsConditions() {
    
    return (
        <>

        <DisclaimerContainer>

            <Particles id="particle-disclaimer" options={Options} style={{ position: 'fixed'}}/>

            <Textbox id='Risk Disclaimer'>

                <TextWrapper>

                    <Title> {TermsConditionsData[0]['title']} </Title>
                    <Paragraph style={{ textAlign: 'justify',fontStyle: 'italic' }}> {TermsConditionsData[0]['paragraph']} </Paragraph>
                    
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[1]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[2]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[2]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[3]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[4]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[5]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet1.map(data => (
                             <li key={data.id} style={{fontFamily: 'Roboto'}}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[6]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[6]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[7]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[8]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[9]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[10]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[11]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[12]['title']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[12]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[13]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[14]['title']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[14]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[15]['title']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet2.map(data => (
                             <li key={data.id} style={{fontFamily: 'Roboto'}}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[15]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '20px' }}> {TermsConditionsData[16]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[17]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[18]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[19]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[20]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[21]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet3.map(data => (
                             <li key={data.id} style={{fontFamily: 'Roboto'}}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[22]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[23]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[24]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[25]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[26]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[27]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[28]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[29]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[30]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[31]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[32]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[33]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[34]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[35]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[36]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[37]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[38]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[39]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[40]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[41]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet4.map(data => (
                             <li key={data.id} style={{fontFamily: 'Roboto'}}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[42]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[43]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[44]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[45]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[46]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[47]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[48]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[49]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[50]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[51]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[52]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[53]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[54]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}>
                        {bullet5.map(data => (
                             <li key={data.id} style={{fontFamily: 'Roboto'}}>  
                                {data.name}
                            </li>
                        ))}
                    </Paragraph>

                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[55]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[56]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[57]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[58]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[59]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[60]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[61]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[62]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[63]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[64]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[65]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[66]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[67]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[68]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[69]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[70]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[71]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[72]['paragraph']} </Paragraph>
                   
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[73]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[74]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[75]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[76]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[77]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[78]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[79]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[80]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[81]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[82]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[83]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[84]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[85]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[86]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[87]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '22px' }}> {TermsConditionsData[88]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[89]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[90]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[91]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[92]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[93]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[94]['paragraph']} </Paragraph>

                    <Paragraph style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '28px' }}> {TermsConditionsData[95]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[96]['paragraph']} </Paragraph>
                    <Paragraph style={{ textAlign: 'justify' }}> {TermsConditionsData[97]['paragraph']} </Paragraph>

                </TextWrapper>
            </Textbox>
        
        </DisclaimerContainer>
            
        </>
    )
}

export default TermsConditions