import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const SocialContainer = styled.div`
    background-color: #101522;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and  (max-width: 1100px) {
    margin-top: -1px;
    margin-right: -1px;
    }

    @media screen and  (max-width: 810px) {
      margin-top: -1px;
      margin-right: -2px;
    }

    @media screen and  (max-width: 720px) {
      margin-top: -1px;
      margin-right: -4px;
    }


`

export const SocialWrapper = styled.div`
  width: 100%;
  display: column;
  justify-content: center;
  align-items: center;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 30%;
  padding-right: 30%;
`

export const SocialIconLink = styled(Link)`
  color: #fff;
  font-size: 40px;

  :hover{
        transform: scale(1.2);
        color: #DECFAC;
    }

    @media screen and  (max-width: 810px) {
      font-size: 35px;
    }

    @media screen and  (max-width: 600px) {
      font-size: 30px;
    }

    @media screen and  (max-width: 450px) {
      font-size: 28px;
    }

    @media screen and  (max-width: 350px) {
      font-size: 25px;
    }

    @media screen and  (max-width: 300px) {
      font-size: 22px;
    }
`;
