import React, {useState} from 'react'

import Sidebar from './Components/Sidebar/Sidebar'
import Header from './Components/Header/Header'
import Hero from './Components/Hero/Hero'
// import TwitterPost from './Components/TwitterPost/TwitterPost'
import HomeInfo from './Components/HomeInfo/HomeInfo'
import BackStory from './Components/BackStory/BackStory'
import Roadmap from './Components/Roadmap/Roadmap'
// import ContractSlider from './Components/Contract/ContractSlider'
// import { ContractData } from './Components/Contract/ContractData'
import Team from './Components/Team/Team'
import Footer from './Components/Footer/Footer'
import Socialbar from './Components/Socialbar/Socialbar'
import Disclaimer from './StickyDisclaimer'
// import PopUp from './Components/CookieConsent/PopUp'
// import Newsletter from './Newsletter/Newsletter'
import NFTpreview from './Components/NFTpreview/NFTpreview'


function Home() {

    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Header toggle={toggle}/>
            <Hero />
            {/* <PopUp/> */}
            {/* <TwitterPost /> */}
            <Socialbar />
            <HomeInfo />
            <BackStory />
            <NFTpreview />
            <Roadmap />
            {/* <ContractSlider slides={ContractData}/> */}
            <Team />
            {/* <Newsletter /> */}
            <Footer />
            <Disclaimer />
        </>
    )
}

export default Home
