import styled from 'styled-components';
import bg from '../../../../ImagesVideo/Home/bg.png';

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? '#fff' : '#101522')};
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 1100px) {
    margin-right: -1px;
  }

  @media screen and (max-width: 810px) {
        margin-right: -2px;
    }

    @media screen and (max-width: 720px) {
      margin-right: -4px;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;

`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) => imgStart ? "'col2 col1'" : "'col1 col2'"};

  @media screen and (max-width: 850px) {
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  display: grid;
  grid-area: col1;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 600px;

  @media screen and (max-width: 850px) {
    min-height: 500px;
  }
`;

export const Column2 = styled.div`
  display: grid;
  grid-area: col2;
  align-items: center;
  justify-content:center;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 600px;

  @media screen and (max-width: 850px) {
    min-height: 500px;
  }
`;

export const TextWrapper = styled.div`  

  justify-content: center;
  align-items: center;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;

  :hover{
    background-color:#3C4761;
    border-radius: 5px;
  }

  @media screen and (max-width: 850px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

`;

export const Title = styled.div`
  color: #F7E7CE;
  font-family: Luxurious Roman;
  font-size: 40px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  padding-bottom: 16px;
  text-align:center;

  @media screen and (max-width: 1500px) {
    font-size: 34px;
    line-height: 36px;
  }

  @media screen and (max-width: 850px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
  text-align: justify;

  @media screen and (max-width: 850px) {
    font-size: 14px;
  }
`;

export const ImgWrap = styled.div`
  max-width: 420px;
  max-height: 500px;

  @media screen and (max-width: 850px) {
    max-width: 300px;
  }
`;

export const Img = styled.img`
  width: 100%;
`;
