import React from 'react'
import styled from 'styled-components';


    function StickyDisclaimer() {

        const Container = styled.div`
            padding: 0.2rem;
            color: #000;
            background-color: #DECFAC;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 100000;
        `;
        
        const link = <a style={{fontFamily: 'Arial', fontSize: '1em', fontWeight: 700, color: '#000'}} 
                        href={('/disclaimer')}> Disclaimer:</a>;
          

        return (
            <>

                <Container style={{fontFamily: 'Arial', fontSize: '0.7em', fontWeight: 700, textAlign: 'center'}}>
                    {link} The information presented here does not represent a financial advice and the team is in no way
                    trying to influence your judgment. We advise you to do your own due diligence and ensure compliance with
                    your local laws and regulations at all times.
                </Container>

            </>
    )
}

export default StickyDisclaimer