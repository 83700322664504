export const TermsConditionsData = [
    {
    id: 0,
    title: "Terms and Conditions",
    paragraph: `Last updated: October 19, 2021`
}, 
{
    id: 1,
    paragraph: `Please read these terms and conditions carefully before using Our Service.`
}, 
{
    id: 2,
    title: `Interpretation and Definitions`,
    paragraph: `Interpretation`
},
{
    id: 3,
    paragraph: `The words of which the initial letter is capitalized have meanings defined under the following conditions. 
    The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`
},
{
    id: 4,
    paragraph: `Definitions`
},
{
    id: 5,
    paragraph: `For the purposes of these Terms and Conditions:`
},
{
    id: 6,
    title: `Acknowledgment`,
    paragraph: `These are the Terms and Conditions governing the use of this Service and the agreement that operates 
    between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding 
    the use of the Service.`
},
{
    id: 7,
    paragraph: `Your access to and use of the Service is conditioned on Your acceptance of and compliance with these 
    Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.`
},
{
    id: 8,
    paragraph: `By accessing or using the Service You agree to be bound by these Terms and Conditions. 
    If You disagree with any part of these Terms and Conditions then You may not access the Service.`
},
{
    id: 9,
    title: `You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.`,
    paragraph: `Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the 
    Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and 
    disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy 
    rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.`
},
{
    id: 10,
    paragraph: `Placing Orders for Goods`
},
{
    id: 11,
    paragraph: `By placing an Order for Goods through the Service, You warrant that You are legally capable of entering 
    into binding contracts.`
},
{
    id: 12,
    title: `Your Information`,
    paragraph: `If You wish to place an Order for Goods available on the Service, You may be asked to supply certain 
    information relevant to Your Order including, without limitation, Your name, Your email, Your phone number, Your 
    credit card number, the expiration date of Your credit card, Your billing address, and Your shipping information.`
},
{
    id: 13,
    paragraph: `You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other 
    payment method(s) in connection with any Order; and that (ii) the information You supply to us is true, correct and complete.`
},
{
    id: 14,
    title: `By submitting such information, You grant us the right to provide the information to payment processing third 
    parties for purposes of facilitating the completion of Your Order.`,
    paragraph: `Order Cancellation`
},
{
    id: 15,
    title: `We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:`,
    paragraph: `We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.`
},
{
    id: 16,
    paragraph: `Your Order Cancellation Rights`
},
{
    id: 17,
    paragraph: `Any Goods you purchase can only be returned in accordance with these Terms and Conditions and Our Returns Policy.`
},
{
    id: 18,
    paragraph: `Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to 
    learn more about your right to cancel Your Order.`
},
{
    id: 19,
    paragraph: `Your right to cancel an Order only applies to Goods that are returned in the same condition as 
    You received them. You should also include all of the products instructions, documents and wrappings. Goods 
    that are damaged or not in the same condition as You received them or which are worn simply beyond opening the 
    original packaging will not be refunded. You should therefore take reasonable care of the purchased Goods while 
    they are in Your possession.`
},
{
    id: 20,
    paragraph: `We will reimburse You no later than 14 days from the day on which We receive the returned Goods. 
    We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.`
},
{
    id: 21,
    paragraph: `You will not have any right to cancel an Order for the supply of any of the following Goods:`
},
{
    id: 22,
    paragraph: `Availability, Errors and Inaccuracies`
},
{
    id: 23,
    paragraph: `We are constantly updating Our offerings of Goods on the Service. The Goods available on Our Service
     may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information 
     regarding our Goods on the Service and in Our advertising on other websites.`
},
{
    id: 24,
    paragraph: `We cannot and do not guarantee the accuracy or completeness of any information, including prices, 
    product images, specifications, availability, and services. We reserve the right to change or update information 
    and to correct errors, inaccuracies, or omissions at any time without prior notice.`
},
{
    id: 25,
    paragraph: `Prices Policy`
},
{
    id: 26,
    paragraph: `The Company reserves the right to revise its prices at any time prior to accepting an Order.`
},
{
    id: 27,
    paragraph: `The prices quoted may be revised by the Company subsequent to accepting an Order in the event of 
    any occurrence affecting delivery caused by government action, variation in customs duties, increased shipping 
    charges, higher foreign exchange costs and any other matter beyond the control of the Company. In that event, 
    You will have the right to cancel Your Order.`
},
{
    id: 28,
    paragraph: `Payments`
},
{
    id: 29,
    paragraph: `All Goods purchased are subject to a one-time payment. Payment can be made through various payment 
    methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards or online payment 
    methods (PayPal, for example).`
},
{
    id: 30,
    paragraph: `Payment cards (credit cards or debit cards) are subject to validation checks and authorization by 
    Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or 
    non-delivery of Your Order.`
},
{
    id: 31,
    paragraph: `Promotions`
},
{
    id: 32,
    paragraph: `Any Promotions made available through the Service may be governed by rules that are separate from these Terms.`
},
{
    id: 33,
    paragraph: `If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. 
    If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.`
},
{
    id: 34,
    paragraph: `Content`
},
{
    id: 35,
    paragraph: `Your Right to Post Content`
},
{
    id: 36,
    paragraph: `Our Service allows You to post Content. You are responsible for the Content that You post to the Service, 
    including its legality, reliability, and appropriateness.`
},
{
    id: 37,
    paragraph: `By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, 
    publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your 
    rights to any Content You submit, post or display on or through the Service and You are responsible for protecting 
    those rights. You agree that this license includes the right for Us to make Your Content available to other users 
    of the Service, who may also use Your Content subject to these Terms.`
},
{
    id: 38,
    paragraph: `You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use 
    it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or 
    through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any 
    other rights of any person.`
},
{
    id: 39,
    paragraph: `Content Restrictions`
},
{
    id: 40,
    paragraph: `The Company is not responsible for the content of the Service's users. You expressly understand and agree 
    that You are solely responsible for the Content and for all activity that occurs under your account, whether done so 
    by You or any third person using Your account.`
},
{
    id: 41,
    paragraph: `You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, 
    libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not 
    limited to, the following:`
},
{
    id: 42,
    paragraph: `The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or 
    not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves 
    the right to make formatting and edits and change the manner any Content. The Company can also limit or revoke the use 
    of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or 
    third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service 
    You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under 
    no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, 
    or any loss or damage of any kind incurred as a result of your use of any content.`
},
{
    id: 43,
    paragraph: `Content Backups`
},
{
    id: 44,
    paragraph: `Although regular backups of Content are performed, the Company do not guarantee there will be no loss
     or corruption of data.`
},
{
    id: 45,
    paragraph: `Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted 
    prior to being backed up or that changes during the time a backup is performed.`
},
{
    id: 46,
    paragraph: `The Company will provide support and attempt to troubleshoot any known or discovered issues that may 
    affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of 
    Content or the failure to successfully restore Content to a usable state.`
},
{
    id: 47,
    paragraph: `You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.`
},
{
    id: 48,
    paragraph: `Copyright Policy`
},
{
    id: 49,
    paragraph: `Intellectual Property Infringement`
},
{
    id: 50,
    paragraph: `We respect the intellectual property rights of others. It is Our policy to respond to any claim that 
    Content posted on the Service infringes a copyright or other intellectual property infringement of any person.`
},
{
    id: 51,
    paragraph: `If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work 
    has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must 
    submit Your notice in writing to the attention of our copyright agent via email at devteam@lennyverse.io and include in 
    Your notice a detailed description of the alleged infringement.`
},
{
    id: 52,
    paragraph: `You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting 
    that any Content is infringing Your copyright.`
},
{
    id: 53,
    paragraph: `DMCA Notice and DMCA Procedure for Copyright Infringement Claims`
},
{
    id: 54,
    paragraph: `You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) 
    by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):`
},
{
    id: 55,
    paragraph: `You can contact our copyright agent via email at devteam@lennyverse.io Upon receipt of a notification, 
    the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the 
    challenged content from the Service.`
},
{
    id: 56,
    paragraph: `Intellectual Property`
},
{
    id: 57,
    paragraph: `The Service and its original content (excluding Content provided by You or other users), 
    features and functionality are and will remain the exclusive property of the Company and its licensors.`
},
{
    id: 58,
    paragraph: `The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.`
},
{
    id: 59,
    paragraph: `Our trademarks and trade dress may not be used in connection with any product or service without 
    the prior written consent of the Company.`
},
{
    id: 60,
    paragraph: `Your Feedback to Us`
},
{
    id: 61,
    paragraph: `You assign all rights, title and interest in any Feedback You provide the Company. If for any reason 
    such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty 
    free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such 
    Feedback without restriction.`
},
{
    id: 62,
    paragraph: `Links to Other Websites`
},
{
    id: 63,
    paragraph: `Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.`
},
{
    id: 64,
    paragraph: `The Company has no control over, and assumes no responsibility for, the content, privacy policies, 
    or practices of any third party web sites or services. You further acknowledge and agree that the Company shall 
    not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by 
    or in connection with the use of or reliance on any such content, goods or services available on or through any 
    such web sites or services.`
},
{
    id: 65,
    paragraph: `We strongly advise You to read the terms and conditions and privacy policies of any third-party web 
    sites or services that You visit.`
},
{
    id: 66,
    paragraph: `Termination`
},
{
    id: 67,
    paragraph: `We may terminate or suspend Your access immediately, without prior notice or liability, 
    for any reason whatsoever, including without limitation if You breach these Terms and Conditions.`
},
{
    id: 68,
    paragraph: `Upon termination, Your right to use the Service will cease immediately.`
},
{
    id: 69,
    paragraph: `Limitation of Liability`
},
{
    id: 70,
    paragraph: `Notwithstanding any damages that You might incur, the entire liability of the Company and any of its 
    suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to 
    the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.`
},
{
    id: 71,
    paragraph: `To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable 
    for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for 
        loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy 
        arising out of or in any way related to the use of or inability to use the Service, third-party software and/or 
        third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if 
        the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its 
        essential purpose.`
},
{
    id: 72,
    paragraph: `Some states do not allow the exclusion of implied warranties or limitation of liability for incidental 
    or consequential damages, which means that some of the above limitations may not apply. In these states, each party's 
    liability will be limited to the greatest extent permitted by law.`
},
{
    id: 73,
    paragraph: `"AS IS" and "AS AVAILABLE" Disclaimer`
},
{
    id: 74,
    paragraph: `The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without 
    warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and 
    on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all 
    warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied 
    warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that 
    may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, 
    the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet 
    Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems 
    or services, operate without interruption, meet any performance or reliability standards or be error free or that any 
    errors or defects can or will be corrected.`
},
{
    id: 75,
    paragraph: `Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation 
    or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, 
    content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as 
    to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the 
    Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan 
    horses, worms, malware, timebombs or other harmful components.`
},
{
    id: 76,
    paragraph: `Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable 
    statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in 
    such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable 
    under applicable law.`
},
{
    id: 77,
    paragraph: `Governing Law`
},
{
    id: 78,
    paragraph: `The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your 
    use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.`
},
{
    id: 79,
    paragraph: `Disputes Resolution`
},
{
    id: 80,
    paragraph: `If You have any concern or dispute about the Service, You agree to first try to resolve the dispute 
    informally by contacting the Company.`
},
{
    id: 81,
    paragraph: `For European Union (EU) Users`
},
{
    id: 82,
    paragraph: `If You are a European Union consumer, you will benefit from any mandatory provisions of the law 
    of the country in which you are resident in.`
},
{
    id: 83,
    paragraph: `United States Legal Compliance`
},
{
    id: 84,
    paragraph: `You represent and warrant that (i) You are not located in a country that is subject to the United 
    States government embargo, or that has been designated by the United States government as a "terrorist supporting" 
    country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.`
},
{
    id: 85,
    paragraph: `Severability and Waiver`
},
{
    id: 86,
    paragraph: `Severability`
},
{
    id: 87,
    paragraph: `If any provision of these Terms is held to be unenforceable or invalid, such provision will be 
    changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under 
    applicable law and the remaining provisions will continue in full force and effect.`
},
{
    id: 88,
    paragraph: `Waiver`
},
{
    id: 89,
    paragraph: `Except as provided herein, the failure to exercise a right or to require performance of an obligation 
    under this Terms shall not effect a party's ability to exercise such right or require such performance at any time 
    thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.`
},
{
    id: 90,
    paragraph: `Translation Interpretation`
},
{
    id: 91,
    paragraph: `These Terms and Conditions may have been translated if We have made them available to You on our Service. 
    You agree that the original English text shall prevail in the case of a dispute.`
},
{
    id: 92,
    paragraph: `Changes to These Terms and Conditions`
},
{
    id: 93,
    paragraph: `We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a 
    revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms 
    taking effect. What constitutes a material change will be determined at Our sole discretion.`
},
{
    id: 94,
    paragraph: `By continuing to access or use Our Service after those revisions become effective, 
    You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, 
    please stop using the website and the Service.`
},
{
    id: 95,
    paragraph: `Contact Us`
},
{
    id: 96,
    paragraph: `If you have any questions about these Terms and Conditions, You can contact us:`
},
{
    id: 97,
    paragraph: `By email: devteam@lennyverse.io`
}

]
