import './App.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import NFTpage from './pages/NFTpage/NFTpage';
import Home from './pages/Home/Home';
import Story from './pages/Story/Story';
import WhitePaper from './pages/WhitePaper/WhitePaper';
import Policy from './pages/Policy/Policy';
import Disclaimer from './pages/RiskDisclaimer/RiskDisclaimer';
import TermsConditions from './pages/TermsConditions/TermsConditions';

function App() {
  return (
    
    <Router>
      <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/marketplace" component={NFTpage} exact/>
        <Route path="/story" component={Story} exact />
        <Route path='/whitepaper' component={WhitePaper} exact />
        <Route path='/policy' component={Policy} exact />
        <Route path='/disclaimer' component={Disclaimer} exact />
        <Route path='/termsconditions' component={TermsConditions} exact />

      </Switch>

    </Router>
  );
}

export default App;
