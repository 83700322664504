import React from 'react'

import {StoryPageContainer,
        BackgroundStars,
        Textbox,
        TextWrapper,
        Title,
        Paragraph,
        Options} 
        from './ChapTwoElements'


import { ChapTwoData } from './ChapTwoData';

import Particles from "react-tsparticles"


function ChapTwo() {

    return (
        <>
                <StoryPageContainer>

                <BackgroundStars>
                        <Particles id="particle-2"
                                   options={Options}
                                   style={{position: 'absolute'}}/>
                
                        <Textbox id='chapter two'>
                            <TextWrapper>
                                <Title> {ChapTwoData[0]['title']} </Title>
                                <Paragraph style={{ textAlign: 'justify' }}> {ChapTwoData[0]['paragraph']} </Paragraph>
                            </TextWrapper>
                        </Textbox>
                </BackgroundStars>

                </StoryPageContainer>

            
        </>
    )
}

export default ChapTwo
