import React, {useState} from 'react';
import {InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper,
    Title, Paragraph, Column2, ImgWrap, Img} from './HomeInfoElements';

import {HomeInfoData} from './HomeInfoData'

function HomeInfo() {

    const[image, setImage] = useState('0')

    const selectImage = ( image_nb ) => {
        setImage(image_nb) 
    }

    return (
        <>
        <InfoContainer lightBg={false}>
            <InfoWrapper>
                
                <InfoRow>
                    
                    <Column1>
                        <TextWrapper onMouseEnter={() => selectImage('0')} exact='true'>
                            <Title> {HomeInfoData[0]['title']} </Title>
                            <Paragraph> {HomeInfoData[0]['description']} </Paragraph>
                        </TextWrapper>

                        <TextWrapper onMouseEnter={() => selectImage('1')} exact='true'>
                            <Title> {HomeInfoData[1]['title']} </Title>
                            <Paragraph> {HomeInfoData[1]['description']} </Paragraph>
                        </TextWrapper>

                        <TextWrapper onMouseEnter={() => selectImage('2')} exact='true'>
                            <Title> {HomeInfoData[2]['title']} </Title>
                            <Paragraph> {HomeInfoData[2]['description']} </Paragraph>
                        </TextWrapper>
                    </Column1>
                    
                    <Column2>
                        <ImgWrap>
                            <Img src={HomeInfoData[image]['image']} />
                        </ImgWrap>
                    </Column2>

                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
            
        </>
    )
}

export default HomeInfo

