import styled from 'styled-components';
import { Link } from 'react-router-dom';
import bg from '../../../../ImagesVideo/Team/teambg.webp'

export const TeamContainer = styled.div`
    color: #fff;
    height: auto;
    padding-bottom: 40px;
    background-color: #000;
`;

export const TeamTitle = styled.div`
    font-size: 100px;
    font-family: 'Island Moments';
    justify-content: center;
    text-align: center;
    color: white;
    padding-bottom: 0px;
    padding-top: 30px;
`

export const TeamWrapper = styled.div`
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(3, minmax(14rem, 16rem));
    grid-gap: 30px;
    position: float;
    background-color: #000;    
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 100px;

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(2, minmax(14rem, 16rem));
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, minmax(14rem, 16rem));
    }
`;

export const TeamCardContainer = styled.div`
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 3px solid #DECFAC;

    background-color: #fff;
    transition:0.5s;
    
    animation: ease-in-out;
    
    align-items: center;
    justify-content: center;

    
    :hover{
        transform: scale(1.02);
        box-shadow: 0px 0px 15px 0px;
    }
`;

export const ImageWrapper = styled.div`
    align-items: center;
    justify-content: center;
    background-color: transparent;
    min-height: 100%;
    min-width: 100%;

`

export const Image = styled.img`
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

export const CardTextWrapper = styled.div`
    background-color: #101522;
    margin-top: -5px
`

export const CardTitle = styled.div`
    font-family: 'Island Moments';
    color: #DECFAC;
    font-size:50px;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;

`;

export const CardDescription = styled.div`
    font-family: 'Roboto';
    color: #fff;
    font-size:23px;
    padding-bottom: 1rem;
    text-align: center;

`;

export const SocialIcons = styled.div`
    background-color: #101522;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;


`

export const SocialIconLink = styled(Link)`
  color: #fff;
  font-size: 24px;
  padding-right: 20px;
  
  :hover{
        transform: scale(1.2);
        color: #DECFAC;
    }
`


export const ColoredLine = styled.hr`
    height: 2px;
    width: 100%;
`;