import React from 'react'

import { FaDiscord, FaTwitter, FaYoutube, FaGithub} from 'react-icons/fa'

import {SocialContainer, SocialWrapper, SocialIconLink, SocialIcons} from './SocialbarElements'

function Socialbar() {
    return (
        <>
            <SocialContainer>
                <SocialWrapper>
                    
                    <SocialIcons>
                        <SocialIconLink to={{ pathname: "https://twitter.com/TheLennyVerse" }} target="_blank">
                            <FaTwitter />
                        </SocialIconLink>

                        <SocialIconLink to={{ pathname: "https://discord.gg/ky7KkDrtgx" }} target="_blank">
                            <FaDiscord />
                        </SocialIconLink>

                        <SocialIconLink to={{ pathname: "https://www.youtube.com/channel/UCtBdEuUedfo5MFfL87VnlMQ" }} target="_blank">
                            <FaYoutube/>
                        </SocialIconLink>

                        <SocialIconLink to={{ pathname: "https://github.com/LennyVerse" }} target="_blank">
                            <FaGithub/>
                        </SocialIconLink>
                    </SocialIcons>

                </SocialWrapper>
            </SocialContainer>
        </>
    )
}

export default Socialbar
