import React from 'react'

import {
    RoadmapItemContainer,
    RoadmapItemContent,
    RoadmapItemTitle,
    RoadmapItemDescription,
    Circle
} from './RoadmapElements';


function RoadmapItem( {data} ) {

    return (
        <>
            <RoadmapItemContainer>
           
                    <RoadmapItemContent>
                        
                        <RoadmapItemTitle>
                            {data.title}
                        </RoadmapItemTitle>

                        <RoadmapItemDescription>
                            {data.description}
                        </RoadmapItemDescription>

                    </RoadmapItemContent>

                    <Circle />

            </RoadmapItemContainer>    
        </>
    )
}

export default RoadmapItem
