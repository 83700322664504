import aito from '../../../../ImagesVideo/NFTpreview/aito.webp';
import buzz from '../../../../ImagesVideo/NFTpreview/buzz.webp';
import cyb from '../../../../ImagesVideo/NFTpreview/cyb.webp';
import iron from '../../../../ImagesVideo/NFTpreview/iron.webp';
import knight from '../../../../ImagesVideo/NFTpreview/knight.webp';
import luck from '../../../../ImagesVideo/NFTpreview/luck.webp';
import sam from '../../../../ImagesVideo/NFTpreview/sam.webp';
import shib from '../../../../ImagesVideo/NFTpreview/shib.webp';
import squid from '../../../../ImagesVideo/NFTpreview/squid.webp';



export const NFTpreviewData = [
    {id : 1,
    photo: aito},

    {id : 2,
    photo: buzz},

    {id : 3,
    photo: cyb},

    {id : 4,
    photo: iron},

    {id : 5,
    photo: knight},

    {id : 6,
    photo: luck},
    
    {id : 7,
    photo: sam},

    {id : 8,
    photo: shib},

    {id : 9,
    photo: buzz},

    {id : 10,
    photo: cyb},

    {id : 11,
    photo: iron},

    {id : 12,
    photo: squid},

    {id : 13,
    photo: aito},

    {id : 14,
    photo: sam},

    {id : 15,
    photo: cyb},

    {id : 16,
    photo: buzz},

    {id : 17,
    photo: aito},

    {id : 18,
    photo: squid}
]