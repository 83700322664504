import styled from 'styled-components';

export const RoadmapContainer = styled.div`
  display:flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #000;
  min-height: 50vh;
  margin:0;
  flex-direction: column;
  width: 100%;
`

export const RoadmapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 40px 0;
  position:relative;

  @media screen and (min-width: 701px) {
    ::after{
      background-color: #DECFAC;
      content: '';
      position: absolute;
      left: calc(25% - 2px);
      width: 4px;
      height: 100%;
      z-index:99;
  }
}
  
  @media screen and (min-width: 901px) {
    ::after{
      background-color: #DECFAC;
      content: '';
      position: absolute;
      left: calc(50%);
      width: 4px;
      height: 100%;
      z-index:99;
  }
}

`

export const RoadmapTitle = styled.div`
  font-family: 'Island Moments';
  padding-top: 40px;
  font-size: 100px; 
  color: #fff;
  z-index: 100;
  text-align: center;
`;




export const RoadmapImage = styled.img`
  position: absolute;
  height: 100%;
  top: 0;
  left: 15%;

  padding-top: 10px;

  @media screen and (max-width: 1400px) {
    left: 8%;
  }

  @media screen and (max-width: 1200px) {
    left: 0%;
  }

  @media screen and (max-width: 950px) {
    height: 90%;
    top:auto;
    bottom: 0;
  }

  @media screen and (max-width: 900px) {
    margin-left: -30px;
  }

  @media screen and (max-width: 800px) {
    margin-left: -50px;
  }

  @media screen and (max-width: 710px) {
    margin-left: -55px;
  }

  @media screen and (max-width: 700px) {
    width: 0;
  }
`

export const RoadmapItemContainer = styled.div`
  display:flex;
  align-self: flex-end;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: flex-start;
  margin: 10px 0;
  position:relative;

  @media screen and (min-width: 701px) {
    width: 75%;
  }

  @media screen and (min-width: 901px) {
    width: 50%;
  }

`

export const RoadmapItemContent = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  opacity: 0.9;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid #DECFAC;
  :hover{
    transform: scale(1.02);
    box-shadow: 0px 0px 15px 0px;
  }
  `

export const RoadmapItemTitle = styled.div`
  font-family: 'Luxurious Roman';
  font-weight: bold;
  font-size: 30px;
  color: #DECFAC;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
`

export const RoadmapItemDescription = styled.div`
  font-family: 'Roboto';
  font-size: 15px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  color:#fff;
  text-align: justify;

`

export const Circle = styled.span`

@media screen and (min-width: 701px) {
  background-color: white;
  border: 2px solid #DECFAC;
  position: absolute;
  border-radius: 50%;
  left:-10px;
  bottom: 50%;
  width: 20px;
  height: 20px;
  z-index: 100;
}

@media screen and (min-width: 901px) {
  background-color: white;
  border: 2px solid #DECFAC;
  position: absolute;
  border-radius: 50%;
  left:-8px;
  bottom: 50%;
  width: 20px;
  height: 20px;
  z-index: 100;
}
`


export const Options = {
  "particles": {
    "number": {
      "value": 355,
      "density": {
        "enable": true,
        "value_area": 789.1476416322727
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.48927153781200905,
      "random": false,
      "anim": {
        "enable": true,
        "speed": 0.2,
        "opacity_min": 0,
        "sync": false
      }
    },
    "size": {
      "value": 3,
      "random": true,
      "anim": {
        "enable": true,
        "speed": 2,
        "size_min": 0,
        "sync": false
      }
    },
    "line_linked": {
      "enable": false,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 0.2,
      "direction": "none",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "bubble"
      },
      "onclick": {
        "enable": true,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 83.91608391608392,
        "size": 1,
        "duration": 3,
        "opacity": 1,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
}



export const EarthContainer = styled.img`

  width: 0%;

  @media screen and (max-width: 700px){
    position: absolute;
    width: 17%;
    top: 0;
    left: 0;
    padding-top: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 380px){
    width: 25%;
  }
`

export const MoonContainer = styled.img`
  width: 0%;

  @media screen and (max-width: 700px){
    position: absolute;
    width: 16%;
    top: 0;
    right: 0;
    padding-top: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 380px){
    width: 25%;
  }
`

