import React, {useState} from 'react'
import { ParallaxBanner, ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { animateScroll as scroll } from 'react-scroll';

//import FadeIn from 'react-fade-in';

import {HeroContainer, HeroBg, HeroContent, HeroH1, HeroH2, HeroP, BtnWrap, ArrowForward, ArrowRightBtn} from './HeroElements'
import { Button } from '../ButtonElements'
//import Launching from '../../../Countdown/Launching'

import { Link as LinkS} from 'react-scroll'
import Img from '../../../../ImagesVideo/lenny.webp';
import smoke from '../../../../ImagesVideo/smoke.webp';

function Hero() {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    const topPage = () => {
        scroll.scrollToTop();
    }


    return (
        <HeroContainer id='home' style={{height: window.innerHeight}}>

            <HeroBg>
                <ParallaxProvider>
                    <ParallaxBanner
                        layers={[
                            {
                            image:Img,
                            amount: 0,
                            expanded: false
                            },
                            {
                            image:smoke,
                            amount: 0.2,
                            expanded: false
                            }]}
                            style={{
                                height: "100vh",
                                overflow: "auto !important",
                                backgroundSize: 'cover'

                                }}>
                    </ParallaxBanner>
                </ParallaxProvider>
            </HeroBg>
   
            <HeroContent>
                
            <ParallaxProvider>
                <Parallax y={[0, 30]} tagOuter="figure">

                    <HeroH2 style={{fontFamily: 'Luxurious Roman', fontWeight: 900, textAlign: 'center'}}> 
                        Enter the
                    </HeroH2>

                    <HeroH1 style={{fontFamily: 'Luxurious Roman', fontWeight: 900, textAlign: 'center'}}> 
                        LennyVerse
                    </HeroH1>

                    <HeroP style={{fontFamily: 'Island Moments', fontWeight: 100, textAlign: 'center'}}> 
                        A Story made by the Community
                    </HeroP>

                    <BtnWrap>

                        <Button to={{ pathname: "https://discord.com/invite/ky7KkDrtgx"}} target="_blank" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' 
                        dark='true' onClick={topPage} style={{fontFamily: 'Luxurious Roman', fontSize: '40px'}}> 
                            Join our Discord {hover ? <ArrowForward /> : <ArrowRightBtn />} 
                        </Button>

                    </BtnWrap>

                </Parallax>
            </ParallaxProvider>

                {/*<HeroP> 
                    <TypeWriterEffect
                    textStyle={{fontSize: '0.9em', fontWeight: 800, textAlign: 'center', paddingTop:'60px',color: '#DECFAC', boxshadow:'32px'}}
                    startDelay={5500}
                    hideCursorAfterText 
                    cursorColor="white"
                    text="TO BE LAUNCHED IN"
                    typeSpeed={50}
                    loop /> 
                </HeroP>


                <CountdownContent>
                    <FadeIn delay={7000}>
                        <Launching/>
                    </FadeIn>
                </CountdownContent>*/}

            </HeroContent>
            
            <LinkS to='home' smooth={true} duration={500} offset={-80}/>
            
        </HeroContainer>
    )
}

export default Hero
