import React from 'react';
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu,
    SidebarLink, SideBtnWrap, SidebarRoute} from './SidebarElements';


const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>

            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>

            <SidebarWrapper>
                 
                 <SidebarMenu>

                     <SidebarLink to="/story" onClick={toggle} exact='true'> Story </SidebarLink> 
                     <SidebarLink to="/marketplace" onClick={toggle} exact='true'> NFT </SidebarLink> 
                     <SidebarLink to="/whitepaper" onClick={toggle} exact='true'> Whitepaper </SidebarLink> 
                     <SidebarLink to={{ pathname: "https://app.lennyverse.io/communityIDO"}} onClick={toggle} exact='true'> BuyNow </SidebarLink> 

                 </SidebarMenu>

                <SideBtnWrap>
                    <SidebarRoute to={{ pathname: "https://app.lennyverse.io/"}} target="_blank"> 
                        Launch Apps 
                    </SidebarRoute>
                </SideBtnWrap>

            </SidebarWrapper>
            
        </SidebarContainer>
    )
}

export default Sidebar
