import styled from 'styled-components';
import {MdArrowForward, MdKeyboardArrowRight} from 'react-icons/md';



export const HeroContainer = styled.div`
    
    background:#0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 700px;
    position: relative;
    z-index:1;

    :before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
        z-index: 2;
    }

`;


export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%auto;
    overflow: hidden;
`;

export const ImgBg = styled.img`
    width: 100%;
    height:100%;
    --o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 400px;

    @media screen and (max-width: 1500px){
        margin-top: 280px;
    }

    @media screen and (max-width: 800px){
        margin-top: 200px;
    }

`;

export const HeroH1 = styled.div`
    margin: 0 auto;
    align-self: center;
    display: flex;
    color: #fff;
    font-size: 100px;
    text-align: center;
    transform: translateY(-50%);

    @media screen and (max-width:1400px){
        font-size: 90px;
    }
    
    @media screen and (max-width:960px){
        font-size: 60px;
    }

    @media screen and (max-width:480px){
        font-size: 32px;
    }
`;

export const HeroH2 = styled.div`
    margin: 0 auto;
    align-self: center;
    display: flex;
    color: #fff;
    font-size: 50px;
    text-align: center;
    transform: translateY(-50%);

    @media screen and (max-width:1400px){
        font-size: 40px;
    }
    
    @media screen and (max-width:960px){
        font-size: 30px;
    }

    @media screen and (max-width:480px){
        font-size: 24px;
    }
`;

export const HeroP = styled.p`
    margin: 0 auto;
    align-self: center;
    transform: translateY(-50%);
    color: #fff;
    font-size: 60px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width:960px){
        font-size: 40px;
    }

    @media screen and (max-width:480px){
        font-size: 30px;
    }
`;


export const HeroBtnWrapper = styled.div`
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


export const CountdownContent = styled.div`
    position: absolute;
    opacity: 90%;
    top: 250px;
    display: flex;
    align-items: center;

    @media screen and (max-width:960px){
        top: 320px;
    }

`; 

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  margin-top: 30px;
  transform: translateY(-50%);
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRightBtn = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;