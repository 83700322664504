import React from 'react'
import {
    CardContainer, 
    Image,
    ImageWrapper
} from './NFTpreviewElements'


function NFTpreviewCard( {data} ) {
    return (
        <>
            <CardContainer> 
                
                <ImageWrapper> 
                    <Image src={data.photo}/>
                </ImageWrapper>

            </CardContainer>
        </>
    )
}

export default NFTpreviewCard
