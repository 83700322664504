export const DisclaimerData = [
    {
    id: 0,
    title: "Risk Disclaimer",
    paragraph: `Last updated: August 30, 2021`
}, 
{
    id: 1,
    paragraph: `When purchasing, holding, selling or using LennyVerse cryptographic tokens or any other cryptographic 
    token which we may offer through our website/platform, please consider the following risk factors explained 
    in detail below. `
}, 
{
    id: 2,
    paragraph: `If any of these risk factors occur, the value of your tokens and/or the ability to trade, access or 
    liquidate your positions may be compromised. Therefore, when purchasing cryptographic tokens you expose yourself 
    to several risk factors and potential financial losses. Tokens are high-risk assets and you should only use funds 
    that you can afford to lose entirely when purchasing LennyVerse tokens. `
},
{
    id: 3,
    paragraph: `By purchasing, holding, selling or using LennyVerse tokens by using our services/platform, 
    you agree that LennyVerse is not liable for any loss incurred by you resulting from the use of our services/platform. `
},
{
    id: 4,
    paragraph: `Please take note that this Risk Statement cannot and does not disclose all risks and other aspects 
    involved in holding, trading, or engaging in financing or financed transactions in LennyVerse. It is highly 
    recommended to conduct further research (and seek professional advice) to determine whether purchasing, holding, 
    selling or using LennyVerse tokens is suitable for your particular financial situation and risk tolerance. 
    Risks include, but are not limited to, the following:
    `
},
{
    id: 5,
    title: `1. Market Risk`,
    paragraph: `Whether the market for one or more Cryptographic Tokens will move up or down is unknown. 
    The price of tokens can be subject to dramatic fluctuations and high volatility due to the rapid shifts 
    in offer and demand resulting from several unpredictable and uncontrollable events. Unexpected changes 
    in market sentiment can lead to sharp and sudden moves in price. .Participants should be cautious about 
    holding Tokens. As a result, your tokens are subject to losing all value due to volatile market price 
    swings or flash crashes incurred by Market Risk. `
},
{
    id: 6,
    title: `2. Protocols`,
    paragraph: `Protocols establish the structure of the blockchain, the distributed database that allows for the 
    secure exchange of digital currencies over the Internet. 
    Tokens are recorded on distributed ledgers which are governed by protocols.
    `
},
{
    id: 7,
    title: `2.1 Malfunction, breakdown and/or abandonment of protocols`,
    paragraph: `Any malfunction, breakdown, and/or abandonment of the protocols on which the tokens are based 
    could severely impact the token price and the ability to exchange it.`
},
{
    id: 8,
    title: `2.2 Mining attacks`,
    paragraph: `Some protocols integrate consensus-based mechanisms for the validation of transfers (“Consensus Protocols”). 
    Consensus Protocols are vulnerable to attacks at the stage of validation, where the token transaction is approved by 
    the network. This may affect the accuracy of transactions occurring on the protocol, and in your tokens being 
    misappropriated (e.g double spending attacks).`
},
{
    id: 9,
    title: `2.3 Hacking and security weaknesses`,
    paragraph: `Tokens may be vulnerable to attacks on the security, integrity or operation, including attacks using 
    computing power sufficient to overwhelm the normal operation of the tokens underlying technology, therefore leading 
    to expropriation and/or theft. Bad actors (including hackers, groups and organisation) may attempt to interfere with 
    the protocols or the tokens in a variety of ways, including, but not limited to, malware attacks, denial of service 
    attacks, consensus-based attacks, sybil attacks, smurfing and spoofing.`
},
{
    id: 10,
    paragraph: `Moreover, the protocols are publicly accessible being an open source software. Therefore, anyone can see, 
    modify, and distribute the code as they see fit, but also maliciously exploit any weakness in the protocols during the 
    development stage, resulting in potentially thieving your tokens or affect the functionality of the protocol and of your 
    ability to dispose of your tokens.`
},
{
    id: 11,
    paragraph: `We do not have control over the protocols, as such, we shall not be responsible for any loss arising out 
    of or in connection with the protocols. `
},
{
    id: 12,
    title: `3. Legal risks`,
    paragraph: `The legal status of tokens is uncertain, not harmonised, and unsettled in many jurisdictions.
    Participants are responsible for knowing and understanding how the tokens they hold will be addressed, regulated, 
    and taxed under applicable law.  When acquiring and exchanging a cryptographic token, you must verify if the use of 
    tokens is legal in his/her jurisdiction. You agree not to use cryptocurrency should their use not be legal in the 
    relevant jurisdiction. If a legal and regulatory framework is newly introduced in your jurisdiction, the possession 
    and/or disposal of your token may be subject to financial loss on your part. 
    `
},
{
    id: 13,
    paragraph: `Hence, we are not liable for any loss on your end resulting from a legal and regulatory 
    framework put in place in your jurisdiction. `
},
{
    id: 14,
    title: `4. Taxation`,
    paragraph: `You are responsible to determine if purchasing, owning, selling, or using cryptographic tokens, 
    or the appreciation or depreciation of these assets over-time are subject to tax implications in your jurisdiction. 
    You should seek professional tax advice to understand what tax obligations to abide by. 
    We are not responsible for withholding, collecting, reporting, paying, and/or remitting any taxes resulting from 
    your acquisition of cryptographic token. We are not responsible for any legal or financial consequences on your end 
    if you do not comply with your tax obligations. `
},
{
    id: 15,
    title: `5. Unanticipated risks`,
    paragraph: `In addition to the risks included in this document, there are other risks associated with purchasing, 
    holding, selling or using LennyVerse tokens that we cannot foresee. Risks may further materialise as unanticipated 
    variations or combinations of the risks discussed in this section.`
},
{
    id: 16,
    paragraph: `This Risk Statement is not exhaustive as it cannot and does not disclose all risks and other aspects 
    involved in purchasing, holding, selling or using LennyVerse tokens. We are not liable for any loss suffered 
    on your end as a result of unanticipated risks.`
}

]
