import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import {PdfWrapper, PageNumber,ArrowLeft, ArrowRight, DownloadButton,
    BtnWrap, ArrowForward, ArrowRightBtn, PageControleContainer, PDFDocumentWrapper} from "./PdfViewerElements";
  

function PdfController(props) {
 
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); 

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    if (pageNumber > 1) {
        changePage(-1);
    } else {
        changePage(numPages - 1)
    }
  }

  function nextPage() {
      if (pageNumber < numPages) {
        changePage(1);
      } else {
        changePage(-numPages + 1)
      }
  }

  const { pdf } = props;

  const [hover, setHover] = useState(false)

  const onHover = () => {
      setHover(!hover)
  }

  return (
    
    <PdfWrapper>

        <PageControleContainer>


        <ArrowLeft onClick={previousPage} />
        
        <PageNumber>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </PageNumber>

        <ArrowRight onClick={nextPage} />

        </PageControleContainer>

        <PDFDocumentWrapper>
        <Document file={pdf} options={{ workerSrc: "pdf.worker.js" }} 
                  onLoadSuccess={onDocumentLoadSuccess} >
          
          <Page pageNumber={pageNumber} style={{}}/>

        </Document>

        </PDFDocumentWrapper>
        
        <BtnWrap>
            <DownloadButton 
            onMouseEnter={onHover} 
            onMouseLeave={onHover} 
            primary='true' 
            dark='true'
            to='LennyVerse.pdf'
            target="_blank" download>
                
                Download Our White Paper {hover ? <ArrowForward /> : <ArrowRightBtn />}
            
            </DownloadButton>
        
        </BtnWrap>

      </PdfWrapper>

  );
}



export default  PdfController;