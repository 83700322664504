import React, {useState} from 'react';

import { StoryTitle, 
        Container, 
        BtnWrap, 
        ArrowForward, 
        ArrowRightBtn,
        TextWrapper,
        Title,
        Paragraph,
        InfoWrapper,
        InfoRow} from './BackStoryElements'

import { Button } from '../ButtonElements'
import { animateScroll as scroll } from 'react-scroll';



function BackStorySlider() {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    const topPage = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Container id='story'>

                <StoryTitle>
                    Lenny's Adventure
                </StoryTitle>

                <InfoWrapper>

                    <InfoRow>
                        <TextWrapper>
                            <Title> My Name is Lenny </Title>

                            <Paragraph> Nobody really knows where Lenny comes from. Some say he was born from a cactus flower that bloomed 
                                in the middle of the desert while others speak of a tadpole that bathed in a drop of rain blessed by the moon gods. 
                                The truth is that we will never know the legendary origin of Lenny because he has no real past, only a destiny to fulfill. </Paragraph>
                            
                            <br/>
                            <br/>

                            <Title> Golden Age of Space Conquest </Title>

                            <Paragraph> The news is out, the golden age of space conquest has begun! All the thrill seekers are to fly to the 
                                moon in their latest high-tech rocket. Lenny plans to assemble a SpaceY Fat-con-Heavy while admiring Elon, the king 
                                of space pirates, who is the first man to have discovered the truth about Mars and the void century of the Red Planet. </Paragraph>
                        
                            <br/>
                            <br/>

                            <Title> Let's Start the Space Race </Title>

                            <Paragraph> Lenny wants to realize his wildest dream: to walk on the moon and dance with his girlfriend 
                                while drinking a glass of bird milk, even though he doesn't have a girlfriend yet. Birds don't make 
                                milk either. Anyway, he wants to operate the craziest and most innovative technologies to carry off 
                                the SPACE RACE. He has a dream that one day... Nevermind. This day is upon us as Lenny now faces his destiny. </Paragraph>
                        </TextWrapper>
                    </InfoRow>

                </InfoWrapper>

                <BtnWrap>
                    <Button to='/story' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' 
                    dark='true' onClick={topPage} style={{fontFamily: 'Luxurious Roman', fontSize: '40px'}}> 
                        Read the Story {hover ? <ArrowForward /> : <ArrowRightBtn />} 
                    </Button>
                </BtnWrap>

            </Container>
        </>
    )
}

export default BackStorySlider;
