import mael from '../../../../ImagesVideo/Team/mael.webp';
import bryan from '../../../../ImagesVideo/Team/Bryan.webp';
import nicolas from '../../../../ImagesVideo/Team/Nicolas.webp';
import jerome from '../../../../ImagesVideo/Team/Jerome.webp';
import arii from '../../../../ImagesVideo/Team/Arii.webp';
import arthur from '../../../../ImagesVideo/Team/Arthur.webp';


export const TeamData = [
    {id : 1,
    name: 'The Doc',
    description: 'Project Co-Founder, Blockchain Developer, Lead Web Developer, Data Scientist',
    linkedin: 'https://www.linkedin.com/in/arii-sichoix-743571111/',
    twitter:'https://twitter.com/Arii0x0',
    telegram:'https://t.me/AriiTahiti',
    photo: arii},

    {id : 2,
    name: 'The Architech',
    description: 'Project Co-Founder, Financial Economist, Lead Graphic Designer, Web Developer',
    linkedin: 'https://www.linkedin.com/in/jeromepansi/',
    twitter:'https://twitter.com/jerome_pansi',
    telegram:'https://t.me/jeromepansi',
    photo: jerome},

    {id : 3,
    name: 'The Notorious',
    description: 'Marketing Manager, Community Manager, Social Media Manager, Business Advisor',
    linkedin: 'https://www.linkedin.com/in/nicolasleou/',
    twitter:'https://twitter.com/freshnicolas',
    telegram:'https://t.me/nicolaslele',
    photo: nicolas},

    {id : 4,
    name: 'The Brain',
    description: 'Software Engineer, Blockchain Developer, Backend Developer',
    linkedin: 'https://www.linkedin.com/in/bryan-sichoix-8259321a0',
    twitter:'https://twitter.com/BSichoix',
    telegram:'https://t.me/bryansx99',
    photo: bryan},

    {id : 5,
    name: 'The Hacker',
    description: 'Software Engineer, Blockchain Developer, Business Advisor',
    linkedin: 'https://www.linkedin.com/in/lenny-dev-320b4821a/',
    twitter:'https://twitter.com/LennyDevv',
    telegram:'https://t.me/lennydev',
    photo: mael},

    {id : 6,
        name: 'The Excalibur',
        description: 'Community Manager, Social Media Manager, Business Analyst',
        linkedin: 'https://www.linkedin.com/in/arthur-charpentier-678814125/',
        twitter:'https://twitter.com/Arthur_Charp',
        telegram:'https://t.me/arthur_charp',
        photo: arthur}
    
        
]