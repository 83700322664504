import styled from 'styled-components';

import lenny1 from '../../../../ImagesVideo/StoryPage/lenny1.webp' 
import lenny2 from '../../../../ImagesVideo/StoryPage/lenny2.webp' 
import lenny3 from '../../../../ImagesVideo/StoryPage/lenny3.webp' 

export const StoryPageContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  flex-direction: column;
  background-size: cover;
  
`

export const BackgroundStars = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    --o-object-fit: cover;
    object-fit: cover;

    box-shadow: 0px -20px 60px 30px #decfac;
    background-color: #000000;
    z-index: 0;

`


export const Textbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`


export const TextWrapper = styled.div`  

  text-align: center;
  justify-content: center;
  align-items: center;
  margin:grid-auto-flow;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

`

export const Title = styled.div`
  color: #DECFAC;
  font-size: 26px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 22pt;

`;

export const Paragraph = styled.p`
  padding-top: 200px;
  padding-bottom: 200px;
  
  max-width: 1000px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#fff' : 'white')};
`;



export const Options = {
    background: {
        color: {
        value: "#000000",
        },
    },
    fpsLimit: 60,
    interactivity: {
        detectsOn: "window",
        events: {
        onClick: {
            enable: true,
            mode: "bubble",
        },
        onHover: {
            enable: true,
            mode: "grab",
        },
        resize: true,
        },
        modes: {
        bubble: {
            distance: 100,
            duration: 2,
            opacity: 0.8,
            size: 20,
        },
        push: {
            quantity: 4,
        },
        repulse: {
            distance: 20,
            duration: 0.4,
        },
        },
    },
    particles: {
        groups: {
            z5000: {
            number: {
                value: 70
            },
            zIndex: {
                value: 5000
            }
            },
            z7500: {
            number: {
                value: 30
            },
            zIndex: {
                value: 7500
            }
            },
            z2500: {
            number: {
                value: 50
            },
            zIndex: {
                value: 2500
            }
            },
            z1000: {
            number: {
                value: 40
            },
            zIndex: {
                value: 1000
            }
            }
        },
        color: {
        value: "#ffffff",
        },
        collisions: {
        enable: false,
        },
        move: {
        angle: {
            value: 10,
            offset: 0
            },
        direction: "bottom",
        enable: true,
        outMode: "out",
        random: false,
        speed: 3,
        straight: false,
        attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
        }
        },
        number: {
        density: {
            enable: true,
            value_area: 800,
        },
        value: 80,
        },
        opacity: {
            value: 1,
            random: false,
            animation: {
            enable: false,
            speed: 3,
            minimumValue: 0.1,
            sync: false
            }
        },
        shape: {
        type: "circle",
        },
        size: {
        random: true,
        value: 3,
        },
    },
    detectRetina: true,
    emitters: {
        position: {
          y: -20,
          x: 70
        },
        rate: {
          delay: 5,
          quantity: 1
        },
        size: {
          width: 0,
          height: 0
        },
        particles: {
          shape: {
            type: "images",
            options: {
              images: [
                {
                    src: lenny1,
                    width: 205,
                    height: 267
                  },
                  {
                    src: lenny2,
                    width: 205,
                    height: 267
                  },
                  {
                    src: lenny3,
                    width: 205,
                    height: 267
                }
              ]
            }
          },
          size: {
            value: 300
          },
          move: {
            speed: 10,
            outModes: {
              default: "destroy",
              left: "none"
            },
            straight: false
          },
          zIndex: {
            value: 0
          },
          rotate: {
            value: {
              min: 0,
              max: 360
            },
            animation: {
              enable: true,
              speed: 10,
              sync: true
            }
          }
        }
      }
    }