import styled from 'styled-components';

export const Background = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    --o-object-fit: cover;
    object-fit: cover;
    margin-top: -850px;

    box-shadow: 0px -20px 60px 30px #decfac;
    background: #000000;
    z-index: 0;

    @media screen and (max-width: 1100px) {
        margin-top: -750px;
      }
    `

export const ChapOneContainer = styled.div`
    font-family: 'Droid Serif', serif;
    font-size: 14px;
    color: #2f2f2f;
    background-color: #f9f7f1;
    position: relative;
    text-align: center;
    padding-top: 100px;
`

export const Header = styled.div`
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 80px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    line-height: 72px;
    margin-bottom: 20px;
` 
export const Paragraph= styled.div`
    margin-top: 0;
    margin-bottom: 20px;
    text-align: justify;
`


export const SubHead = styled.div`
    text-transform: uppercase;
    border-bottom: 2px solid #2f2f2f;
    border-top: 2px solid #2f2f2f;
    padding: 12px 0 12px 0;
`

export const Content = styled.div`
    font-size: 0;
    line-height: 0;
    word-spacing: -.31em;
    display: inline-block;
    margin: 30px 2% 0 2%;
`

export const Column = styled.div`
    font-size: 14px;
    line-height: 20px;
    width: 27.5%;
    display: inline-block;
    padding: 0 1% 0 1%;
    vertical-align: top;
    border-right: 1px solid #2f2f2f;
    margin-bottom: 50px;
    transition: all .7s;

    @media screen and (max-width: 1100px) {
        width: 37.5%;
      }

    @media screen and (max-width: 900px) {
        width: 57.5%;
    }

    @media screen and (max-width: 700px) {
        width: 75%;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`

export const Headline1 = styled.div`
position: relative;
font-weight: 400;
font-style: italic;
font-size: 36px;
line-height: 40px;
box-sizing: border-box;
padding: 10px 0 10px 0;
`

export const Headline2 = styled.div`
font-weight: 400;
font-size: 18px;
line-height: 30px;
box-sizing: border-box;
padding: 0px 0 0px 0;
border-top: 1px solid #2f2f2f;
border-bottom: 1px solid #2f2f2f;
width: 70%;
height: 100%;
display: block;
margin: 0 auto;
`

export const Headline3 = styled.div`
font-weight: 700;
font-size: 30px;
line-height: 30px;
text-transform: uppercase;
padding: 10px 0 10px 0;
`

export const Headline4 = styled.div`
font-weight: 400;
font-size: 32px;
line-height: 40px;
text-transform: uppercase;
font-style: italic;
box-sizing: border-box;
padding: 10px 0 10px 0;
`

export const GIF= styled.img`
    width: 80%;
`