import styled from 'styled-components';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';


export const MarketContainer = styled.div`
  display:flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #101522;
  min-height: 50vh;
  margin:0;
  flex-direction: column;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const TitleImage = styled.img`
  position: relative;
  width: 80%;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding-top: -1000px;
  padding-bottom: -1000px;
  opacity: 80%;
  `;

export const CardWrapper = styled.div`
  width: 100%;
  perspective: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBackground = styled.div`
    position: absolute;
    background: linear-gradient(
        20deg,
        hsl(${props => props.hue}, 100%, 15%),
        hsl(${props => props.hue - 3050}, 64%, 40%)
      );
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    background-size: cover;
    --o-object-fit: cover;
    object-fit: cover;
`;

export const CardImage = styled.img`
  position: absolute;
  width: 100%;
  `;

export const CardContainer = styled(motion.div)`
  width: 285px;
  height: 500px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
  background-size: 350%;
  background-color: #1d1f21;
  color: #fff;
  position: relative;
  cursor: grab;

  border-radius: 30px;
  border: 3px solid #DECFAC;
`;

export const CircleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  border-top-right-radius: 25px;
`;

export const Circle = styled.img`
  position: absolute;
  width: 350px;
  height: 350px;
  top: -4.2em;
  right: -10em;
  z-index: 5;
  background-color: transparent;
  border-radius: 50%;
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1.2;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 15px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex: 0.8;
  padding: 0 1em;
`;

export const Text = styled.h1`
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  z-index: 10;
  font-size: 76px;
  font-weight: 900;
`;

export const NFTWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NFT = styled(motion.div)`
  width: 100%;
  height: 290px;
  z-index: 99;
  user-select: none;
  margin-right: 1em;
  margin-top: 4em;
  img {
    width: auto;
    height: 100%;
    user-select: none;
  }
`;

export const Options = {
    "particles": {
      "number": {
        "value": 355,
        "density": {
          "enable": true,
          "value_area": 789.1476416322727
        }
      },
      "color": {
        "value": "#ffffff"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#000000"
        },
        "polygon": {
          "nb_sides": 5
        },
        "image": {
          "src": "img/github.svg",
          "width": 100,
          "height": 100
        }
      },
      "opacity": {
        "value": 1,
        "random": false,
        "anim": {
          "enable": true,
          "speed": 0.2,
          "opacity_min": 0,
          "sync": false
        }
      },
      "size": {
        "value": 3,
        "random": true,
        "anim": {
          "enable": true,
          "speed": 2,
          "size_min": 0,
          "sync": false
        }
      },
      "line_linked": {
        "enable": false,
        "distance": 150,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 0.2,
        "direction": "none",
        "random": true,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode": "bubble"
        },
        "onclick": {
          "enable": true,
          "mode": "push"
        },
        "resize": true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked": {
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 83.91608391608392,
          "size": 5,
          "duration": 3,
          "opacity": 1,
          "speed": 3
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  }


  export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(16rem, 18rem));
    grid-gap: 130px;
    position: float;
    background-color: #101522;    
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 30px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(16rem, 18rem));
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(1, minmax(16rem, 18rem));
}
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5em 6px 0 6px;
  line-height: 1.4;
`;

export const MediumText = styled.span`
  font-size: 18px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
`;

export const SmallText = styled.span`
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

export const SpacedHorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BuyButton = styled(Link)`
  padding: 10px 16px;
  background-color: #decfac;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  border: 3px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 290ms ease-in-out;
  border-radius: 8px;
  &:hover {
    background-color: transparent;
    color: #fff;
    border: 3px solid #fbbe01;
  }
`;

export const Logo = styled.div`
  position:relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 17%;
    ;
  }
`;

export const NFTdescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: linear-gradient(
    20deg,
    hsl(${props => props.hue}, 100%, 15%),
    hsl(${props => props.hue - 3050}, 64%, 40%)
  );
  background-size: cover;
`

export const Textbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`

export const TextWrapper = styled.div`  

  text-align: center;
  justify-content: center;
  align-items: center;
  margin:grid-auto-flow;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

`;

export const Title = styled.div`
  font-family: Luxurious Roman;
  color: #DECFAC;
  font-size: 60px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-bottom: 100px;
`;

export const Paragraph = styled.p`
  max-width: 1000px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#fff' : 'white')};
`;