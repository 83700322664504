import React from 'react';
import Particles from "react-tsparticles"
import title from '../../../../ImagesVideo/NFTmarket/title.webp'; 


import {MarketContainer,
        TitleImage,
        CardBackground,
        Options,
        Wrapper,
        NFTdescriptionContainer,
        Textbox,
        TextWrapper,
        Paragraph} from './NFTmarketElements'

import { NFTmarketData, NFTdescriptionData } from './NFTmarketData';
import NFTcard from './NFTcard';


function NFTmarket() {
    const [hue] = React.useState(29);

    return (
        <>
        
        <MarketContainer>

                <TitleImage src={title}/>
                
                <CardBackground hue={hue}>
                  <Particles id="particle-nft2" options={Options} style={{ position: 'absolute', z_index: -100}}/>
                </CardBackground>

                <NFTdescriptionContainer hue={hue}>
                    <Textbox id='NFT description'>
                        <TextWrapper>
                            <Paragraph style={{ textAlign: 'justify' , fontFamily: 'Roboto', fontSize: '20px'}}> {NFTdescriptionData[1]['paragraph']} </Paragraph>
                            <Paragraph style={{ textAlign: 'justify', fontFamily: 'Luxurious Roman', fontWeight: 'bold', fontSize: '34px'}}> {NFTdescriptionData[2]['title']} </Paragraph>
                            <Paragraph style={{ textAlign: 'justify' , fontFamily: 'Roboto', fontSize: '20px'}}> {NFTdescriptionData[2]['paragraph']} </Paragraph>
                            <Paragraph style={{ textAlign: 'justify', fontFamily: 'Luxurious Roman', fontWeight: 'bold', fontSize: '34px'}}> {NFTdescriptionData[3]['title']} </Paragraph>
                            <Paragraph style={{ textAlign: 'justify' , fontFamily: 'Roboto', fontSize: '20px'}}> {NFTdescriptionData[3]['paragraph']} </Paragraph>
                            <Paragraph style={{ textAlign: 'justify', fontFamily: 'Luxurious Roman', fontWeight: 'bold', fontSize: '34px' }}> {NFTdescriptionData[4]['title']} </Paragraph>
                            
                            <Paragraph style={{ textAlign: 'justify' , fontFamily: 'Roboto', fontSize: '20px'}}> {NFTdescriptionData[4]['paragraph']} </Paragraph>
                        </TextWrapper>
                    </Textbox>
                </NFTdescriptionContainer>

                <Wrapper>
                    {NFTmarketData.map((data, index) => 
                            <NFTcard data = {data} key={index} />
                    )}
                </Wrapper>

        </MarketContainer>
        </>
    )
}

export default NFTmarket